<template>
    <a-modal 
        :visible="visible" 
        :centered="true" 
        :maskClosable="false" 
        title="对接ERP" 
        :confirm-loading="confirmLoading"
        @ok="clickEdit"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-alert style="margin-bottom: 20px;" message="提示：目前仅支持对接“吉客云”和“网店管家”，两个平台接口通用，更多ERP将陆续对接。" type="warning" show-icon/>
        <a-form ref="editFormRef" :model="editFormState" :labelCol="{span:5}">
            <a-form-item label="接口地址">
                <span>{{ editFormState.apiurl }}</span>
                <a-tag style="margin-left: 8px;" color="#f50" @click="clickCopy(editFormState.apiurl)">复制链接</a-tag>
            </a-form-item>
            <a-form-item label="AppKey" name="appkey" :rules="[{ required: true, message: '请输入AppKey' }]">
                <a-input v-model:value="editFormState.appkey" placeholder="请输入AppKey" allowClear/>
            </a-form-item>
            <a-form-item label="AppSecret" name="appsecret" :rules="[{ required: true, message: '请输入AppSecret' }]">
                <a-input v-model:value="editFormState.appsecret" placeholder="请输入AppSecret" allowClear/>
            </a-form-item>
            <a-form-item label="Token" name="token" :rules="[{ required: true, message: '请输入Token' }]">
                <a-input v-model:value="editFormState.token" placeholder="请输入token" allowClear/>
            </a-form-item>
            <a-form-item label="状态" name="status">
                <a-radio-group v-model:value="editFormState.status">
                    <a-radio :value="1">开启</a-radio>
                    <a-radio :value="2">关闭</a-radio>
                </a-radio-group>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import {defineComponent,ref,computed} from 'vue';
    import useUser from '@/stores/user';
    import { editErp } from '@/api/erp';
    import common from '@/utils/common';
    import { message } from 'ant-design-vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }){
            const userStore = useUser();
            const userInfo = computed(() => userStore.userInfo);

            const confirmLoading = ref(false);

            //编辑
            const editFormRef = ref();
            const editFormState = ref({
                apiurl:userInfo.value.erpInfo && userInfo.value.erpInfo.apiurl ? userInfo.value.erpInfo.apiurl : '',
                appkey:userInfo.value.erpInfo && userInfo.value.erpInfo.appkey  ? userInfo.value.erpInfo.appkey : '',
                appsecret:userInfo.value.erpInfo && userInfo.value.erpInfo.appsecret ? userInfo.value.erpInfo.appsecret : '',
                token:userInfo.value.erpInfo && userInfo.value.erpInfo.token ? userInfo.value.erpInfo.token : '',
                status:userInfo.value.erpInfo && userInfo.value.erpInfo.status ? userInfo.value.erpInfo.status : 2,
            });

            const clickEdit = () => {
                editFormRef.value.validateFields().then(values => {
                    confirmLoading.value = true;

                    editErp(values).then((res) => {
                        if (res.status == 1) {
                            emit('cancel');
                            userStore.GetUserAndOrganizationInfo();
                            message.success(res.message);
                        }else{
                            message.error(res.message);
                        }

                        confirmLoading.value = false;
                    })
                }).catch(info => {
                
                });
            }

            //复制
            const clickCopy = async (text) => {
                common.copy(text);
            }

            return {
                confirmLoading,
            
                editFormRef,
                editFormState,
                clickEdit,

                clickCopy
            }
        }
    })
</script>