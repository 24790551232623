import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.less';

import router from './router';

import pinia from "./stores/store";

import './permission'

const app = createApp(App);

import common from './utils/common.js';
app.config.globalProperties.$common = common;

import moment from "moment"; 
app.config.globalProperties.$moment = moment;

import './registerServiceWorker'

app.use(Antd).use(router).use(pinia).mount('#app')
