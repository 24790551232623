<template>
    <a-upload
        accept="image/*"
        action="https://upload-z0.qiniup.com"
        list-type="picture-card"
        class="picture-uploader"
        :max-count="1"
        :show-upload-list="false"
        :data = dataState
        :before-upload="beforeUpload"
        @change="handleChange"
    >   
            <div v-if="loading">
                <loading-outlined></loading-outlined>
                <div class="ant-upload-text">上传中</div>
            </div>
            <div v-else>
                <img v-if="imageUrl" :src="$common.cdnurl(imageUrl,true)" />
                <div v-else>
                    <plus-outlined/>
                    <div v-if="uploadText" class="ant-upload-text">{{uploadText}}</div>
                </div>
            </div>
    </a-upload>
</template>
<script>
    import { defineComponent,ref,watch} from 'vue';
    import { PlusOutlined,LoadingOutlined} from '@ant-design/icons-vue';
    import {getQiniuUploadToken} from '@/api/upload';
    import {message,Upload} from 'ant-design-vue';

    export default defineComponent({
        components: {
            PlusOutlined,
            LoadingOutlined,
        },
        props: {
            value: {
                type: String,
                default:'',
            },
            
            uploadText: {
                type: String,
                default:'',
            },
        },
        emits:{
            "update:value":(val)=>{
                return val
            }
        },
        setup(props,{ emit }) {
            const loading = ref(false);

            const imageUrl = ref(props.value);
            watch(props,(newVal)=>{
               imageUrl.value = newVal.value;
            })

            const dataState = ref({
                token:'',
                name:'',
            });

            const beforeUpload = async (file) => {
                const isLt5M = file.size / 1024 / 1024 < 5;
                if (!isLt5M) {
                    message.error('图片超过5M，请先压缩图片');
                    return Upload.LIST_IGNORE;
                }else{
                    const res = await getQiniuUploadToken();
                    dataState.value = {
                        token:res.result,
                        name :file.name
                    }
                }
            }

            const handleChange = info => {
                if (info.file.status === 'uploading') {
                    loading.value = true;
                    return;
                }

                if (info.file.status === 'done') {
                    imageUrl.value = info.file.response.key;
                    loading.value = false;

                    emit('update:value',info.file.response.key);

                    message.success('上传成功');
                }

                if (info.file.status === 'error') {
                    loading.value = false;
                    message.error('上传失败');
                }
            }

            return {
                loading,

                imageUrl,
                dataState,

                beforeUpload,
                handleChange
            }
        }
    })
</script>
<style>
    .picture-uploader > .ant-upload {
        width: 100px;
        height: 100px;
    }

    .picture-uploader img{
        display: block;
        width: 90px;
        height: 90px;
        object-fit: cover;
    }

    .picture-uploader .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .picture-uploader .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
</style>