import request from '@/utils/request'

const versionApi = {
    GetLatestVersion: '/userAdminApi/version/getLatestVersion',
}

//获取最新版本
export function getLatestVersion(parameter){
    return request({
        url: versionApi.GetLatestVersion,
        method: 'get',
        params: parameter
    })
}