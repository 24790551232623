import request from '@/utils/request'

const uploadApi = {
    GetQiniuUploadToken:'/common/upload/getQiniuUploadToken',
}

//获取七牛上传凭证
export function getQiniuUploadToken(parameter){
    return request({
        url: uploadApi.GetQiniuUploadToken,
        method: 'get',
        params: parameter
    })
}