<template>
    <a-modal 
        :visible="visible" 
        :closable="closable"
        :centered="true" 
        :width="300"
        :maskClosable="false" 
        title="绑定微信" 
        :body-style="{ padding: '20px' }"
        :footer="null"
        @cancel="$emit('cancel')"
        style="-webkit-app-region: no-drag"
    >
        <div class="content">
            <div style="position: relative;">
                <div id="qrcode" class="qrcode"> </div>
                <div v-if="codeStatus==3" class="ww_scanCodeCard_left_cover"></div>
            </div>
            <div v-if="codeStatus==1 || codeStatus==4"  class="scan">扫码绑定微信</div>
            <div v-if="codeStatus==2" class="ww_scanCodeCard_info">
                <div class="ww_scanCodeCard_info_title"><check-circle-outlined style="margin-right: 5px;color: #64c54d;"/>扫描成功</div>
                <div class="ww_scanCodeCard_info_desc">请在微信中点击确认</div>
            </div>
            <div v-if="codeStatus==3" class="ww_scanCodeCard_info">
                <div class="ww_scanCodeCard_info_title"><check-circle-outlined style="margin-right: 5px;color: #64c54d;"/>绑定微信成功</div>
            </div>
        </div>
    </a-modal>
</template>
<script>
import {defineComponent,ref,onMounted,nextTick,onBeforeUnmount} from 'vue';
import storage from 'store'
import {v4 as uuidv4} from "uuid"
import QRCode from "qrcodejs2"
import {getQcodeStatus} from '@/api/qrcode'

import {CheckCircleOutlined} from '@ant-design/icons-vue';

import useUser from '@/stores/user';
import { message } from 'ant-design-vue';
export default defineComponent({
    components: {
        CheckCircleOutlined
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        closable: {
            type: Boolean,
            default: true
        },
    },

    setup(props,{ emit }) {
        const userStore = useUser();

        // 生成二维码
        const codeStatus =ref(1);// 1-未扫码 2-扫描成功 3-确定成功 4-取消
            
        const timer = ref(null);

        const uuid = ref(); //唯一标识
        uuid.value = uuidv4();

        const token = storage.get('token');

        //生成二维码
        const clickCreatQrCode = () => {
            document.getElementById("qrcode").innerHTML = "";
            let url= `https://h5.maoxiubiji.com/pages/bind_wechat/index?uuid=${uuid.value}&token=${token}`;
            new QRCode(document.getElementById("qrcode"), {
                text: url, // 需要转换为二维码的内容
                width: 800,
                height:800,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.L
            })
        }

        const clearTime = () => {
            clearTimeout(timer.value);
            timer.value = null;
        }

        // 轮询获取二维码状态
        const clickGetQcodeStatus = () => {
            timer.value = setTimeout(function() {
                getQcodeStatus({uuid:uuid.value}).then((res) => {
                    if(res.status == 1 ){
                        codeStatus.value = res.result.status;
                        if(res.result.status == 1 || res.result.status == 2 || res.result.status == 4){
                            clickGetQcodeStatus();
                        }else{
                            clearTime();
                            userStore.GetUserAndOrganizationInfo();
                            emit('cancel');
                            message.success('微信绑定成功');
                        }
                    }
                })
            },2000);
        }

        onMounted(()=>{
            nextTick(() => {
                // 生成二维码
                clickCreatQrCode();

                // 轮询获取二维码状态
                clickGetQcodeStatus();
            })
        })

        onBeforeUnmount(() => {
            clearTime();
        })

        return {
            codeStatus
        }
    }
})
</script>
<style lang="less" scoped>
    .content{
        text-align: center;
        .qrcode{
            border: 0;
            outline: 0;
            display: block;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            width: 150px;
            height: 150px;
            background-color: #fff;
            padding: 10px;
            border: 1px solid #eee;
            border-radius: 5px;
        }

        .ww_scanCodeCard_left_cover {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #FFF;
            opacity: .8;
        }
        .scan{
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 14px;
            color: #666;
        }

        .ww_scanCodeCard_info {
            .ww_scanCodeCard_info_title{
                font-size: 14px;
                line-height: 22px;
            }
            .ww_scanCodeCard_info_desc{
                font-size: 12px;
                color: #787878;
            }
        }
    }
</style>