<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="1200"
        :maskClosable="false"
        title="标签模板编辑器"
        :footer="null"
        :body-style="{ padding: '0px' }"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-layout style="height:600px;">
            <a-layout-header style="background-color: #fff;border-bottom: 1px solid #f0f0f0;padding: 0 24px;">
                <div style="margin-top: 16px;">
                    <a-form layout="inline">
                        <a-col :span="6">
                            <a-form-item label="模板名称">
                                <a-input placeholder="请输入模板名称" allowClear/>
                            </a-form-item>
                        </a-col>
                    </a-form>
                </div>
            </a-layout-header>
            <a-layout>
                <a-layout-sider width="230" style="background-color: #fff;border-right: 1px solid #f0f0f0;overflow-y: auto;overflow-x: hidden;">
                    <div class="control">
                        <a-typography-title :level="5">基础模块</a-typography-title>
                        <div class="control-main">
                            <div v-for="(item, index) in module" :item="item" :key="index" class="item" draggable="true" @dragstart="(event) => dragStart(event, item)" @click="clickModule(item)">{{item['name']}}</div>
                        </div>
                        <a-typography-title :level="5">背景模板</a-typography-title>
                        <div style="margin-top: 20px;">
                            <div style="margin-top: 8px;">
                                <img style="width: 100%;" src="https://oss.maoxiubiji.com/uploads/20240318/FiFv6hobxfP4j1nFtHhppPII3jqc.png"/>
                            </div>
                            <div style="margin-top: 8px;">
                                <img style="width: 100%;" src="https://oss.maoxiubiji.com/uploads/20240318/FiFv6hobxfP4j1nFtHhppPII3jqc.png"/>
                            </div>
                            <div style="margin-top: 8px;">
                                <img style="width: 100%;" src="https://oss.maoxiubiji.com/uploads/20240318/FiFv6hobxfP4j1nFtHhppPII3jqc.png"/>
                            </div>
                        </div>
                    </div>
                </a-layout-sider>
                <a-layout-content style="background-color: #fff;">
                    <div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;background-color: #f5f5f5;">
                        <div class="container" :style="{width:w+'px',height:h+'px'}" @click.right.prevent @dragover.prevent @drop="drop">
                            <div class="container-grid-8"></div>
                            <div class="container-bg" :style="{backgroundImage:'url('+bg+')'}"></div>
                            <div style="height: 100%;" v-if="newModule.length > 0">
                                <vue-draggable-resizable 
                                    v-for="(item, index) in newModule" :item="item" :key="index" 
                                    :w="item['w']" 
                                    :h="item['h']" 
                                    :x="item['x']" 
                                    :y="item['y']" 
                                    :parent="true" 
                                    :snap="true"
                                    :snapTolerance="10"
                                    :grid="[1,1]"
                                    :isConflictCheck="false"
                                    :resizable="true"
                                    @refLineParams="getRefLineParams" 
                                >
                                    <div v-if="item['type'] == 'text'" :style="item['style']">
                                        {{ item['content'] }}
                                    </div>
                                    <div v-else-if="item['type'] == 'images'">
                                        <img :style="item['style']" :src="item['src']" />
                                    </div>

                                    <div v-else-if="item['type'] == 'data'" :style="item['style']">
                                        {{ item['content'] }}
                                    </div>

                                    <div v-else-if="item['type'] == 'barcode'">
                                        <img :style="item['style']" :src="item['src']" />
                                    </div>

                                    <div v-else-if="item['type'] == 'qrcode'">
                                        <img :style="item['style']" :src="item['src']" />
                                    </div>
                                </vue-draggable-resizable>
                            </div>
                        </div>
                    </div>
                </a-layout-content>
                <a-layout-sider width="300" style="background-color: #fff;border-left: 1px solid #f0f0f0;">
                    <div class="card__header">组件编辑</div>
                </a-layout-sider>
            </a-layout>
            <a-layout-footer style="padding: 15px 0px;text-align: center;background-color: #fff;border-top: 1px solid #f0f0f0;">
                <a-button @click="$emit('cancel')">取消</a-button>
                <a-button style="margin-left: 8px" type="primary">保存模板</a-button>
            </a-layout-footer>
        </a-layout>
    </a-modal>
</template>
<script>
    import {defineComponent,ref} from 'vue';
    import VueDraggableResizable from "vue-draggable-resizable-gorkys/src/components/vue-draggable-resizable.vue"
    import 'vue-draggable-resizable-gorkys/src/components/vue-draggable-resizable.css'
    import { DeleteOutlined } from '@ant-design/icons-vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},
        components: {
            VueDraggableResizable,

            DeleteOutlined
        },
        setup() {
            const dragStart = (event,item) => {
                // 设置拖拽时传输的数据
                event.dataTransfer.setData('text/plain',JSON.stringify(item));
            }

            const drop = (event) => {
                // 获取拖拽时传输的数据
                let data = event.dataTransfer.getData("text/plain");
                data = JSON.parse(data);
                console.log(event);
                
                // data['moduleX'] = event.layerX;
                // data['moduleY'] = event.layerY;
                newModule.value.push(data);
            }

            const clickModule = (item) => {
                newModule.value.push(item);
            }

            const w = ref(560);
            const h = ref(304);
            const bg = '';

            const module = ref([
                {
                    type :'text',
                    name :'自定义文本',
                    w:100,
                    h:30,
                    x:0,
                    y:0,
                    content:'自定义文本',
                    style:{
                        fontSize:'18px',
                        fontWeight:'normal',
                        color:'#333333',
                        textAlign:'left'
                    }
                },
                {
                    type :'data',
                    name :'自定义数据',
                    w:100,
                    h:30,
                    x:0,
                    y:0,
                    content:'自定义数据',
                    style:{
                        fontSize:'18px',
                        fontWeight:'normal',
                        color:'#333333',
                        textAlign:'left'
                    }
                },
                {
                    type :'images',
                    name :'自定义图片',
                    w:50,
                    h:50,
                    x:0,
                    y:0,
                    src:'https://images.qmai.cn/resource/20210913185016/2023/01/12/no-good.png',
                    style:{
                        width:'48px',
                        height:'48px',
                        borderRadius:'0px'
                    }
                },
                {
                    type :'barcode',
                    name :'产品条码',
                    w:140,
                    h:42,
                    x:0,
                    y:0,
                    src:'https://images.qmai.cn/resource/20210913185016/2022/01/10/barcode.png',
                    style:{
                        width:'138px',
                        height:'40px'
                    }
                },
                {
                    type :'qrcode',
                    name :'笔记二维码',
                    w:50,
                    h:50,
                    x:0,
                    y:0,
                    src:'https://images.qmai.cn/resource/20210913185016/2021/11/14/qrcode.png',
                    style:{
                        width:'48px',
                        height:'48px'
                    }
                }
            ]);

            const newModule = ref([

            ]);

            const getRefLineParams = (params) => {
                const { vLine, hLine } = params
                vLine.value = vLine
                hLine.value = hLine
            }

            return{
                dragStart,
                drop,
                clickModule,

                w,
                h,
                bg,
                module,
                newModule
            }
        }
    })
</script>
<style lang="less" scoped>

    .control{
        padding: 24px;
    }

    .control .control-main{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: 20px;
    }

    .control .control-main .item{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px 20px 5px;
        padding: 5px 7px;
        font-size: 12px;
        text-align: center;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        cursor: pointer;
    }

    .container{
        position: relative;
        border-radius: 4px;
        box-shadow: 0 6px 16px 0 rgba(0,0,0,.1);
        background-color: #fff;
    }

    .container-grid-8{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        z-index: 9;
    }

    .container-grid-8{
        background: linear-gradient(90deg, hsla(0, 0%, 90%, .3) 1px, transparent 1%), linear-gradient(hsla(0, 0%, 90%, .3) 1px, transparent 1%), transparent;
        background-size: 8px 8px;
    }

    .container-bg{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }

    .card__header{
        padding: 18px 20px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 15px;
    }
</style>
<style>
    .vdr{
        z-index: 99 !important;
    }
   .active{
      border: 1px solid #ff5603;
   }
   .handle{
        border: 1px solid #ff5603;
   }

   .del{
        display: none;
        position: absolute;
   }
   .active .del{
        display: block;
   }
</style>