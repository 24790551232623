import { defineStore } from 'pinia';

import { asyncRouterMap,constantRouterMap } from '@/config/router.config'

const useAsyncRouter = defineStore({
    id:'async_router',

    state(){  // 存放的就是模块的变量
        return{
            routers: constantRouterMap,
            addRouters: [],
        }
    },

    getters:{ // 相当于vue里面的计算属性，可以缓存数据
        
    },

    actions:{ // 可以通过actions 方法，改变 state 里面的值。
        GenerateRoutes(){
            return new Promise(resolve => {
                this.addRouters = asyncRouterMap;
                this.routers = constantRouterMap.concat(asyncRouterMap)

                resolve()
            })
        }
    }
})

export default useAsyncRouter;