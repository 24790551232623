import request from '@/utils/request'

const orderApi = {
    GetOrderList:'/userAdminApi/order/getOrderList',
    AddOrder:'/userAdminApi/order/addOrder',
    GetOrderQcodeStatus:'/userAdminApi/order/getOrderQcodeStatus',
}

//获取订单列表
export function getOrderList(parameter){
    return request({
        url: orderApi.GetOrderList,
        method: 'get',
        params: parameter
    })
}

//添加订单
export function addOrder(parameter){
    return request({
        url: orderApi.AddOrder,
        method: 'post',
        data: parameter
    })
}

//获取订单二维码状态
export function getOrderQcodeStatus(parameter){
    return request({
        url: orderApi.GetOrderQcodeStatus,
        method: 'get',
        params: parameter
    })
}