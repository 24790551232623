<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="400"
        :maskClosable="false"
        title="创建新企业产品库"
        :confirm-loading="confirmLoading"
        @cancel="$emit('cancel')"  
        @ok="clickAddNewOrganization"
        style="-webkit-app-region: no-drag"
    >
        <a-form ref="addOrganizationNameFormRef" :model="addOrganizationNameFormState">
            <a-form-item name="organization_name" :rules="[{ required: true, message: '请输入企业名称' }]">
                <a-input size="large" v-model:value="addOrganizationNameFormState.organization_name" placeholder="请输入企业名称" allowClear/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import {defineComponent,ref,reactive} from 'vue';

    import {addNewOrganization} from '@/api/organization';
    import { message } from 'ant-design-vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }){
            const confirmLoading = ref(false);

            //创建新企业
            const addOrganizationNameFormRef = ref();
            const addOrganizationNameFormState = reactive({
                organization_name:'',
            });
            const clickAddNewOrganization = () => {
                addOrganizationNameFormRef.value.validateFields().then(values => {
                    confirmLoading.value = true;
                    addNewOrganization(values).then((res) => {
                        if (res.status == 1) {
                            emit('refresh');
                            emit('cancel');
                            message.success(res.message);
                        }else{
                            message.error(res.message);
                        }

                        confirmLoading.value = false;
                    })
                }).catch(info => {
                
                });
            }

            return {
                confirmLoading,

                addOrganizationNameFormRef,
                addOrganizationNameFormState,
                clickAddNewOrganization,
            }
        }
    })
</script>