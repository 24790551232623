<template>
    <div>
        <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
            <a-typography-title :level="5" style="margin-bottom: 20px;">标签模板</a-typography-title>
            <div style="margin-top: 16px;-webkit-app-region: no-drag">
                <div class="table-tool" style="margin-bottom: 8px">
                    <div class="table-operator">
                        <a-button @click="diyTemplateModal" type="primary">添加模板</a-button>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <a-row :gutter="16">
                        <a-col :span="8">
                            <a-card hoverable>
                                <template #cover>
                                    <div style="width: 100%;position: relative;overflow: hidden;">
                                        <img style="height:148px;object-fit: cover;border-radius: 10px 10px 0  0;" src="https://oss.maoxiubiji.com/uploads/20240316/FpKQewNrYI3K6x4Mt1b0AsnFXya2.png" />
                                        <div class="list-item-default">默认</div>
                                        <div class="list-item-use">使用中</div>
                                    </div>
                                </template>
                                <a-card-meta title="产品标签模板"></a-card-meta>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </a-card>

        <diy-template-modal v-if="diyTemplateVisible" :visible="diyTemplateVisible" @cancel="diyTemplateVisible = false"></diy-template-modal>
    </div>
</template>
<script>
    import {defineComponent,ref} from 'vue';
    import diyTemplateModal from '@/components/print/diyTemplateModal';
    export default defineComponent({
        components: {
            diyTemplateModal
        },

        setup() {
            //diy模板
            const diyTemplateVisible = ref(false);
            const diyTemplateModal = () => {
                diyTemplateVisible.value = true;
            }

            return{
                diyTemplateVisible,
                diyTemplateModal
            }
        }
    })
</script>
<style lang="less" scoped>
    .list-item-default{
        position: absolute;
        top: 0;
        left: 0;
        width: 45px;
        height: 24px;
        color:#fff;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background: #ff5603;
        border-radius: 8px 0 8px 0;
    }
    .list-item-use{
        position: absolute;
        top: 10px;
        right: -28px;
        width: 100px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #ff5603;
        transform: rotate(45deg);
    }
</style>