<template>
    <a-modal 
        :visible="visible" 
        :centered="true" 
        :maskClosable="false" 
        title="添加企业成员" 
        :footer="null"
        :body-style="{ padding: '15px 24px' }"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-tabs v-model:activeKey="activeKey" @change="tabChange">
            <a-tab-pane key="1" tab="手机号">
                <a-form ref="addFormRef" :model="addFormState" :labelCol="{span:4}">
                    <a-form-item label="手机号" name="mobile" :rules="[{ required: true, message: '请输入手机号' }]">
                        <a-input v-model:value="addFormState.mobile" placeholder="请输入手机号" allowClear/>
                    </a-form-item>
                    <a-form-item label="姓名" name="name" :rules="[{ required: true, message: '请输入姓名' }]">
                        <a-input v-model:value="addFormState.name" placeholder="请输入姓名" allowClear/>
                    </a-form-item>

                    <div style="display: flex;justify-content: space-between;">
                        <div></div>
                        <div>
                            <a-button key="back" @click="$emit('cancel')">取消</a-button>
                            <a-button style="margin-left: 8px;" key="submit" type="primary" :loading="confirmLoading" @click="clickAdd">确定</a-button>
                        </div>
                    </div>
                </a-form>
            </a-tab-pane>
            <a-tab-pane key="2" tab="企业二维码">
                <div style="text-align: center;">
                    <div style="font-size: 15px;margin-top: 10px;margin-bottom: 20px;">{{userInfo['organization_name']}}</div>
                    <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 10px;">
                        <div id="qrcode" class="qrcode"> </div>
                        <div class="scan">扫一扫二维码打开或分享给同事</div>
                        <a-button @click="clickSaveQrcode" type="primary">保存到电脑</a-button>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="企业链接">
                <div style="text-align: center;">
                    <div style="font-size: 15px;margin-top: 10px;margin-bottom: 20px;">{{userInfo['organization_name']}}</div>
                    <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 4px;margin-bottom: 20px;">
                        <div style="margin-bottom: 15px;">邀请你加入{{userInfo['organization_name']}}，可通过此链接 https://note.maoxiubiji.com/#/user/invite?user_code={{userInfo['user_code']}}&organization_code={{userInfo['organization_code']}}</div>

                        <a-button type="primary" @click="clickCopy(`邀请你加入${userInfo['organization_name']}，可通过此链接 https://note.maoxiubiji.com/#/user/invite?user_code=${userInfo['user_code']}&organization_code=${userInfo['organization_code']}`);">复制链接</a-button>
                    </div>
                </div>
            </a-tab-pane>
            <!-- <a-tab-pane key="2" tab="企业邀请码">
                <div style="text-align: center;">
                    <div style="font-size: 15px;margin-top: 10px;margin-bottom: 20px;">{{userInfo['organization_name']}}</div>
                    <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 4px;margin-bottom: 20px;">
                        <span style="font-size: 25px;font-weight: 500;letter-spacing:10px">{{userInfo['organization_code']}}</span>
                        <div @click="clickResetOrganizationCode" style="margin-top: 15px;font-size: 14px;color: #ff5603;display: flex;align-items: center;justify-content: center;cursor: pointer;"><reload-outlined style="margin-right: 5px;"/>重置邀请码</div>
                    </div>
                </div>
            </a-tab-pane> -->
        </a-tabs>
    
    </a-modal>
</template>
<script>
    import {defineComponent,ref,reactive,computed,nextTick} from 'vue';

    import {ReloadOutlined} from '@ant-design/icons-vue';

    import QRCode from "qrcodejs2"
    import html2canvas from 'html2canvas'
    import FileSaver from 'file-saver';

    import useUser from '@/stores/user';
    import { addUser} from '@/api/user';
    // import { resetOrganizationCode} from '@/api/organization';
    import { message } from 'ant-design-vue';

    import common from '@/utils/common';
    
    export default defineComponent({
        components: {
            ReloadOutlined
        },

        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }){
            const userStore = useUser();
            const userInfo =  computed(() => userStore.userInfo);

            const confirmLoading = ref(false);

            //添加手机号
            const addFormRef = ref();
            const addFormState = reactive({
                mobile:'',
                name:''
            });
    
            const clickAdd = () => {
                addFormRef.value.validateFields().then(values => {
                    confirmLoading.value = true;
                    addUser(values).then((res) => {
                        if(res.status == 1){
                            emit('refresh');
                            emit('cancel');
                            message.success(res.message);
                        }else{
                            message.error(res.message);
                        }

                        confirmLoading.value = false;
                        
                    })
                }).catch(info => {
                
                });
            }


            //生成二维码
            const clickCreatQrcode = () => {
                document.getElementById("qrcode").innerHTML = "";
                // 生成二维码
                new QRCode(document.getElementById("qrcode"), {
                    text: 'https://note.maoxiubiji.com/#/user/invite?user_code='+userInfo.value.user_code+'&organization_code='+userInfo.value.organization_code, // 你的扫码内容,填网址
                    width: 800, // 二维码宽度 
                    height: 800, // 二维码高度
                    colorDark: "#000000", //二维码颜色
                    colorLight: "#ffffff", //二维码背景色
                    correctLevel: QRCode.CorrectLevel.L, //从上至下生成码密度越来越高 L - M - Q - H
                    // 容错率越高,越复杂
                })
            }

            const tabChange = (activeKey) => {
                if(activeKey == 2){
                    nextTick(() => {
                    // 生成二维码
                        clickCreatQrcode();
                    })
                }
            }   

            const clickSaveQrcode = () => {
                html2canvas(document.getElementById("qrcode"), {
                    scale: 6,
                    useCORS: true, // 解决跨域保存图片的问题
                }).then(canvas => {
                    // 将canvas内容保存为文件并下载
                    canvas.toBlob((blob) => {
                        FileSaver(blob,'企业二维码.png')
                    })
                })
            }

            //重置企业邀请码
            // const clickResetOrganizationCode = () => {
            //     resetOrganizationCode().then((res) => {
            //         if(res.status == 1){
            //             userStore.GetUserAndOrganizationInfo();
            //             message.success(res.message);
            //         }else{
            //             message.error(res.message);
            //         }
            //     })
            // }

            //复制
            const clickCopy = async (text) => {
                common.copy(text);
            }

            return {
                userInfo,

                activeKey: ref('1'),

                confirmLoading,

                addFormRef,
                addFormState,
                clickAdd,

                tabChange,
                clickSaveQrcode,
                // clickResetOrganizationCode,

                clickCopy,
            }
        }
    })
</script>
<style lang="less" scoped>
    .scan{
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 14px;
        color: #666;
    }

    .qrcode{
        border: 0;
        outline: 0;
        display: block;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        width: 200px;
        height: 200px;
        background-color: #fff;
        padding: 10px;
        border: 1px solid #eee;
        border-radius: 5px;
    }
</style>