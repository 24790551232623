import request from '@/utils/request'

const organizationApi = {
    GetOrganizationList:'/userAdminApi/organization/getOrganizationList',
    JoinExistingOrganization:'/userAdminApi/organization/joinExistingOrganization',
    AddNewOrganization:'/userAdminApi/organization/addNewOrganization',
    EditOrganization:'/userAdminApi/organization/editOrganization',
    ResetOrganizationCode:'/userAdminApi/organization/resetOrganizationCode',
    ActivationOrganization:'/userAdminApi/organization/activationOrganization',
}

/**
 * 获取企业列表
 */
export function getOrganizationList(parameter){
    return request({
        url: organizationApi.GetOrganizationList,
        method: 'get',
        params: parameter
    })
}

/**
 * 加入已有企业
 */
export function joinExistingOrganization(parameter){
    return request({
        url: organizationApi.JoinExistingOrganization,
        method: 'post',
        data: parameter
    })
}

/**
 * 创建新企业
 */
export function addNewOrganization(parameter){
    return request({
        url: organizationApi.AddNewOrganization,
        method: 'post',
        data: parameter
    })
}

/**
 * 编辑企业
 */
export function editOrganization(parameter){
    return request({
        url: organizationApi.EditOrganization,
        method: 'post',
        data: parameter
    })
}

/**
 * 重置企业邀请码
 */
export function resetOrganizationCode(){
    return request({
        url: organizationApi.ResetOrganizationCode,
        method: 'post',
    })
}

/**
 * 激活企业
 */
export function activationOrganization(parameter){
    return request({
        url: organizationApi.ActivationOrganization,
        method: 'post',
        data: parameter
    })
}