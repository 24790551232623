<template>
    <div>
        <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
            <a-typography-title :level="5" style="margin-bottom: 20px;">发票管理</a-typography-title>
            <div style="margin-top: 16px;-webkit-app-region: no-drag">
                <div class="table-tool" style="margin-bottom: 8px">
                    <div class="table-operator">
                        <a-button>合并申请</a-button>
                        <a-button style="margin-left: 8px">开票信息</a-button>
                        <a-button style="margin-left: 8px">开票记录</a-button>
                    </div>
                </div>

                <a-table 
                    ref="table"
                    size="middle" 
                    :row-key = "record => record.id"
                    :columns = "columns"
                    :data-source = "dataSource" 
                    :pagination = "pagination"
                    :loading = "loading"
                    @change = "handleTableChange"
                >
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'service_name'">
                            <span>{{text}}{{ record['service_year'] }}年</span>
                        </template>
                        <template v-else-if="column.dataIndex === 'pay_status'">
                            <a-tag v-if="text=='10'" color="red">未支付</a-tag>
                            <a-tag v-else-if="text=='20'" color="blue">已支付</a-tag>
                            <a-tag v-else>已取消</a-tag>
                        </template>
                    </template>
                </a-table>
            </div>
        </a-card>
    </div>
</template>
<script>
    import { defineComponent,computed} from 'vue';
    import { usePagination } from 'vue-request';
    import {getOrderList} from '@/api/order'
    export default defineComponent({
        setup() {
            //table
            const columns = [
                {
                    title: '订单号',
                    dataIndex: 'order_no',
                    align: 'left',
                },
                {
                    title: '购买服务',
                    dataIndex: 'service_name',
                    align: 'left',
                },
                {
                    title: '价格',
                    dataIndex: 'pay_price',
                    align: 'left',
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    align: 'left',
                    fixed: 'right',
                },
            ];

            const queryData = params => {
                return getOrderList(params).then((res) => {
                    return res.result;
                })
            };

            const {
                data,
                run,
                loading,
                current,
                pageSize,
                refresh
            } = usePagination(queryData);

            const dataSource = computed(() => data.value ? data.value.data : []);

            const pagination = computed(() => (
                {
                    total: data.value ? data.value.totalCount : '',
                    current: current.value,
                    pageSize: pageSize.value,
                    showTotal:(total) => `共 ${total} 条`,
                    pageSizeOptions:['10', '20', '30', '40', '50', '100'],
                    showSizeChanger:true,
                }
            ));

            const handleTableChange = (pag, filters, sorter) => {
                run({
                    current:pag.current,
                    pageSize:pag.pageSize,
                })
            }

            return {
                queryData,
                columns,
                dataSource,

                pagination,
                loading,
                handleTableChange,
            }
        }
    })
</script>