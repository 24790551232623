import request from '@/utils/request'

const distributionApi = {
    GetDirectDistributionOrderList:'/userAdminApi/distribution/getDirectDistributionOrderList',
    GetIndirectDistributionOrderList:'/userAdminApi/distribution/getIndirectDistributionOrderList',
    GetDistributionUserBalance:'/userAdminApi/distribution/getDistributionUserBalance',
    GetDistributionUserBalanceLog:'/userAdminApi/distribution/getDistributionUserBalanceLog',
    GetDistributionUserList:'/userAdminApi/distribution/getDistributionUserList',
}

//获取分销直接订单
export function getDirectDistributionOrderList(parameter){
    return request({
        url: distributionApi.GetDirectDistributionOrderList,
        method: 'get',
        params: parameter
    })
}

//获取分销间接订单
export function getIndirectDistributionOrderList(parameter){
    return request({
        url: distributionApi.GetIndirectDistributionOrderList,
        method: 'get',
        params: parameter
    })
}

//获取分销用户余额
export function getDistributionUserBalance(){
    return request({
        url: distributionApi.GetDistributionUserBalance,
        method: 'get',
    })
}

//获取分销用户余额额记录
export function getDistributionUserBalanceLog(parameter){
    return request({
        url: distributionApi.GetDistributionUserBalanceLog,
        method: 'get',
        params: parameter
    })
}

//获取我的分销用户列表
export function getDistributionUserList(parameter){
    return request({
        url: distributionApi.GetDistributionUserList,
        method: 'get',
        params: parameter
    })
}

