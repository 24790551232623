import request from '@/utils/request'

const friendApplyApi = {
    GetFriendApplyList:'/userAdminApi/friend_apply/getFriendApplyList',
    PassApply:'/userAdminApi/friend_apply/passApply',
    NoPassApply:'/userAdminApi/friend_apply/noPassApply',
    GetApplyBadgeSubCount:'/userAdminApi/friend_apply/getApplyBadgeSubCount'
}

//获取申请列表
export function getFriendApplyList(parameter){
    return request({
        url: friendApplyApi.GetFriendApplyList,
        method: 'get',
        params: parameter
    })
}

//通过申请
export function passApply(parameter){
    return request({
        url: friendApplyApi.PassApply,
        method: 'post',
        data: parameter
    })
}

//拒绝申请
export function noPassApply(parameter){
    return request({
        url: friendApplyApi.NoPassApply,
        method: 'post',
        data: parameter
    })
}

//获取新申请徽标数
export function getApplyBadgeSubCount(){
    return request({
        url: friendApplyApi.GetApplyBadgeSubCount,
        method: 'get',
    })
}
