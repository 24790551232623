<template>
    <div>
        <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
            <a-typography-title :level="5" style="margin-bottom: 20px;">我的激活码</a-typography-title>

            <div style="background-color: #fafafa;padding: 16px;-webkit-app-region: no-drag">
                <a-form ref="searchFormRef" :model="searchFormState" layout="inline"  @finish="clickSearch">
                    <a-form-item label="快速筛选" name="activation_status">
                        <a-radio-group v-model:value="searchFormState.activation_status" @change="clickRadio">
                            <a-radio-button value="all">全部</a-radio-button>
                            <a-radio-button value="20">未激活</a-radio-button>
                            <a-radio-button value="30">已激活</a-radio-button>
                            <a-radio-button value="40">已过期</a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <a-divider />
                    
                    <a-col :span="8">
                        <a-form-item label="激活码" name="activation_code">
                            <a-input v-model:value="searchFormState.activation_code" placeholder="请输入激活码" allowClear/>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-button type="primary" html-type="submit">查询</a-button>
                        <a-button @click="clickReset" style="margin-left: 8px">重置</a-button>
                    </a-col>
                 
                </a-form>
            </div>
            <div style="margin-top: 16px;-webkit-app-region: no-drag">
                <a-table 
                    ref="table"
                    size="middle" 
                    :row-key = "record => record.id"
                    :columns = "columns.filter((col, num) => {
                        if (col.show) {
                            return col
                        }
                    })" 
                    :dataSource = "dataSource" 
                    :pagination = "pagination"
                    :loading = "loading"
                    @change = "handleTableChange"
                    :scroll = "{ x: 'max-content'}"
                >
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'activation_year'">
                            <template v-if="record['activation_year']>0 || record['activation_month']>0 || record['activation_day']>0">
                                <span v-if="record['activation_year']>0">{{record['activation_year']}}年</span>
                                <span v-if="record['activation_month']>0">{{record['activation_month']}}个月</span>
                                <span v-if="record['activation_day']>0">{{record['activation_day']}}天</span>
                            </template>
                            <span v-else>无</span>
                        </template>
                        <template v-if="column.dataIndex === 'service_name'">
                            <span>{{record['service_name']}} {{record['service_year']}}年</span>
                        </template>
                        <template v-if="column.dataIndex === 'activation_status'">
                            <a-tag v-if="text=='10'" color="red">未制卡</a-tag>
                            <a-tag v-if="text=='20'" color="orange">未激活</a-tag>
                            <a-tag v-if="text=='30'" color="blue">已激活</a-tag>
                            <a-tag v-if="text=='40'">已过期</a-tag>
                        </template>
                    </template>
                </a-table>
            </div>
        </a-card>
    </div>
</template>
<script>
    import { defineComponent,ref,reactive,computed} from 'vue';
    import { usePagination } from 'vue-request';
    import { getActivationCodeList} from '@/api/shareholder';
    const queryData = params => {
        return getActivationCodeList(params).then((res) => {
            return res.result;
        })
    };
    export default defineComponent({
        setup() {
            //搜索
            const searchFormRef = ref();
            const searchFormState = reactive({activation_status:'all'});

            const clickRadio = e => {
                run({
                    activation_status:e.target.value,
                    current:1,
                    pageSize:pageSize.value,
                })
            }

            const clickSearch = values => {
                run({
                    pay_status:values.pay_status,
                    activation_code:values.activation_code,
                    current:1,
                    pageSize:pageSize.value,
                })
            }

            const clickReset = () => {
                if (searchFormRef.value !== undefined) searchFormRef.value.resetFields();

                run({
                    current:1,
                    pageSize:pageSize.value,
                })
            }

               //table
               const columns = [
                {
                    title: '激活码',
                    dataIndex: 'activation_code',
                    align: 'left',
                    show: true,
                },
                {
                    title: '有效期',
                    dataIndex: 'activation_year',
                    align: 'left',
                    show: true,
                },
                {
                    title: '激活人姓名',
                    dataIndex: 'name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '激活人手机号',
                    dataIndex: 'mobile',
                    align: 'left',
                    show: true,
                },
                {
                    title: '激活企业',
                    dataIndex: 'organization_name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '激活服务',
                    dataIndex: 'service_name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '激活时间',
                    dataIndex: 'activationtime',
                    align: 'left',
                    show: true,
                },
                {
                    title: '激活状态',
                    dataIndex: 'activation_status',
                    align: 'left',
                    show: true,
                },
                {
                    title: '创建时间',
                    dataIndex: 'createtime',
                    align: 'left',
                    show: true,
                },
            ];

            const {
                data,
                run,
                loading,
                current,
                pageSize,
                refresh
            } = usePagination(queryData);

            const dataSource = computed(() => data.value ? data.value.data : []);

            const pagination = computed(() => ({
                total: data.value ? data.value.totalCount : '',
                current: current.value,
                pageSize: pageSize.value,
                showTotal:(total) => `共 ${total} 条`,
                pageSizeOptions:['10', '20', '30', '40', '50', '100'],
                showSizeChanger:true,
            }));

            const handleTableChange = (pag, filters, sorter) => {
                run({
                    activation_status:searchFormState.activation_status,
                    activation_code:searchFormState.activation_code,
                    current:pag.current,
                    pageSize:pag.pageSize,
                })
            }

            return {
                searchFormRef,
                searchFormState,  
                clickRadio,  
                clickSearch,
                clickReset,

                columns,
                dataSource,
                pagination,
                loading,
                handleTableChange,
            }
        }
    })
</script>