<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="400"
        :maskClosable="false"
        title="编辑企业名称"
        :confirm-loading="confirmLoading"
        @cancel="$emit('cancel')"  
        @ok="clickEditOrganization"
        style="-webkit-app-region: no-drag"
    >
        <a-form ref="editOrganizationFormRef" :model="editOrganizationFormState">
            <a-form-item label="编号" name="organization_id" style="display:none">
                <a-input v-model:value="editOrganizationFormState.organization_id"/>
            </a-form-item>
            <a-form-item name="organization_name" :rules="[{ required: true, message: '请输入企业名称' }]">
                <a-input size="large" v-model:value="editOrganizationFormState.organization_name" placeholder="请输入企业名称" allowClear/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import {defineComponent,ref,reactive} from 'vue';
    import {editOrganization} from '@/api/organization';
    import { message } from 'ant-design-vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
            data:{
                type:Object,
                default: {}
            }
		},

        setup(props,{ emit }){
            const confirmLoading = ref(false);

            //编辑企业
            const editOrganizationFormRef = ref();
            const editOrganizationFormState = reactive({
                organization_id:props.data.organization_id,
                organization_name:props.data.organization_name,
            });
            const clickEditOrganization = () => {
                editOrganizationFormRef.value.validateFields().then(values => {
                    editOrganization(values).then((res) => {
                        if (res.status == 1) {
                            emit('refresh');
                            emit('cancel');
                            message.success(res.message);
                        }else{
                            message.error(res.message);
                        }

                        confirmLoading.value = false;
                    })
                }).catch(info => {
                
                });
            }
            return {
                confirmLoading,

                editOrganizationFormRef,
                editOrganizationFormState,
                clickEditOrganization,
            }
        }
    })
</script>