import request from '@/utils/request'

const userApi = {
    GetUserList:'/userAdminApi/user/getUserList',
    AddUserCheck:'/userAdminApi/user/addUserCheck',
    AddUser:'/userAdminApi/user/addUser',
    EditUser:'/userAdminApi/user/editUser',
    ChangeMobile:'/userAdminApi/user/changeMobile',
    ChangePassword:'/userAdminApi/user/changePassword',
    OrganizationLogin: '/userAdminApi/user/organizationLogin',
    MobileLoginCheck: '/userAdminApi/user/mobileLoginCheck',
    MobileSmsLoginCheck:'/userAdminApi/user/mobileSmsLoginCheck',
    ForgotPasswordLoginCheck:'/userAdminApi/user/forgotPasswordLoginCheck',
    Register: '/userAdminApi/user/register',
    Join:'/userAdminApi/user/join',
    GetUserAndOrganizationInfo:'/userAdminApi/user/getUserAndOrganizationInfo',
    UpdateUserInfo:'/userAdminApi/user/updateUserInfo',
    GetInviteInfo:'/userAdminApi/user/getInviteInfo',
    Logout:'/userAdminApi/user/logout'
}

//获取用户列表
export function getUserList(parameter){
    return request({
        url: userApi.GetUserList,
        method: 'get',
        params: parameter
    })
}

//添加用户权限验证
export function addUserCheck(){
    return request({
        url: userApi.AddUserCheck,
        method: 'post',
    })
}

//添加用户
export function addUser(parameter){
    return request({
        url: userApi.AddUser,
        method: 'post',
        data: parameter
    })
}

//编辑用户
export function editUser(parameter){
    return request({
        url: userApi.EditUser,
        method: 'post',
        data: parameter
    })
}

//修改手机号
export function changeMobile(parameter){
    return request({
        url: userApi.ChangeMobile,
        method: 'post',
        data: parameter
    })
}

//修改密码
export function changePassword(parameter){
    return request({
        url: userApi.ChangePassword,
        method: 'post',
        data: parameter
    })
}

//企业登录
export function organizationLogin(parameter){
    return request({
        url: userApi.OrganizationLogin,
        method: 'post',
        data: parameter
    })
}

//手机号及密码检测
export function mobileLoginCheck(parameter){
    return request({
        url: userApi.MobileLoginCheck,
        method: 'post',
        data: parameter
    })
}

//手机号验证码检测
export function mobileSmsLoginCheck(parameter){
    return request({
        url: userApi.MobileSmsLoginCheck,
        method: 'post',
        data: parameter
    })
}

//忘记密码
export function forgotPasswordLoginCheck(parameter){
    return request({
        url: userApi.ForgotPasswordLoginCheck,
        method: 'post',
        data: parameter
    })
}

//注册
export function register (parameter) {
    return request({
        url: userApi.Register,
        method: 'post',
        data: parameter
    })
}

//加入
export function join(parameter){
    return request({
        url: userApi.Join,
        method: 'post',
        data: parameter
    })
}

//获取用户及企业信息
export function getUserAndOrganizationInfo(){
    return request({
        url: userApi.GetUserAndOrganizationInfo,
        method: 'get',
    })
}

//更新用户信息
export function updateUserInfo(parameter){
    return request({
        url: userApi.UpdateUserInfo,
        method: 'post',
        data: parameter
    })
}

//获取邀请信息
export function getInviteInfo(parameter){
    return request({
        url: userApi.GetInviteInfo,
        method: 'get',
        params: parameter
    })
}

//退出登录
export function logout(){
    return request({
        url: userApi.Logout,
        method: 'post',
    })
}

