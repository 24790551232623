<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :maskClosable="false"
        title="账号设置"
        :footer="null"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-list itemLayout="horizontal" :dataSource="data">
            <template #renderItem="{ item }">
                <a-list-item>
                    <a-list-item-meta>
                        <template #title>
                            <a>{{ item.title }}</a>
                        </template>
                        <template #description>{{ item.description }}</template>
                    </a-list-item-meta>
                    <template #actions>
                        <template v-if="item.actions">
                            <a-button type="primary" size="small" @click="item.actions.callback">{{item.actions.title}}</a-button>
                        </template>
                    </template>
                </a-list-item>
            </template> 
        </a-list>
    </a-modal>

    <!-- 修改手机号 -->
    <change-mobile-modal v-if="changeMobileVisible" :visible="changeMobileVisible" @cancel="changeMobileVisible = false"></change-mobile-modal>
    <!-- 修改微信 -->
    <bind-wechat-modal v-if="bindWechatVisible" :visible="bindWechatVisible" @cancel="bindWechatVisible = false"></bind-wechat-modal>
    <!-- 修改密码 -->
    <change-password-modal v-if="changePasswordVisible" :visible="changePasswordVisible" @cancel="changePasswordVisible = false"></change-password-modal>
</template>
<script>
    import {defineComponent,ref,computed} from 'vue';
    import useUser from '@/stores/user';

    import changeMobileModal from '@/components/accountSetting/changeMobileModal';
    import bindWechatModal from '@/components/accountSetting/bindWechatModal';
    import changePasswordModal from '@/components/accountSetting/changePasswordModal';

    export default defineComponent({
        components: {
            changeMobileModal,
            bindWechatModal,
            changePasswordModal
        },
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup() {
            const userStore = useUser();

            const userInfo = computed(() => userStore.userInfo);

            /**
             * 修改手机号
             */
            const changeMobileVisible = ref(false);
            const changeMobileModal = () => {
                changeMobileVisible.value = true;
            }

            /**
             * 绑定微信
             */
            const bindWechatVisible = ref(false);
            const bindWechatModal = () => {
                bindWechatVisible.value = true;
            }

            /**
             * 修改密码
             */
            const changePasswordVisible = ref(false);
            const changePasswordModal = () => {
                changePasswordVisible.value = true;
            }

            const data = [
                {
                    title: '手机号',
                    description: userInfo.value.mobile ?  '已绑定手机号' : '未绑定手机号，绑定后可通过手机号进行登录',
                    value: '',
                    actions: {
                        title: userInfo.value.mobile ? '修改' : '绑定',
                        callback: () => {
                            changeMobileModal()
                        },
                    },
                },
                {
                    title: '微信',
                    description: userInfo.value.openid ? '已绑定微信' : '未绑定微信，绑定后可通过微信进行登录',
                    value:'',
                    actions: {
                        title: userInfo.value.openid ? '修改' : '绑定',
                        callback: () => {
                            bindWechatModal();
                        },
                    },
                },
                {
                    title: '修改密码',
                    actions: {
                        title: '修改',
                        callback: () => {
                            changePasswordModal()
                        },
                    },
                },
            ];

            return {
                data,

                userInfo,

                changeMobileVisible,
                changeMobileModal,

                bindWechatVisible,
                bindWechatModal,

                changePasswordVisible,
                changePasswordModal,
            }
        }
    })
</script>