<template>
    <a-table 
        ref="table"
        size="middle" 
        :row-key = "record => record.id"
        :columns = "columns"
        :data-source = "dataSource" 
        :pagination = "pagination"
        :loading = "loading"
        @change = "handleTableChange"
        :scroll = "{ x: 'max-content'}"
    >
        <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'content'">
                <span v-if="record['service_type'] == '10' || record['service_type'] == '20'">{{ record['service_name'] }}<span v-if="record['expirationtime']">{{record['order_status'] == '10' ? '新开' : '续费'}}{{record['service_year']}}年，有效期至{{ $moment(record['expirationtime']).format('YYYY-MM-DD')}}</span></span><span v-if="record['service_type'] == '30'">猫嗅群看板发布货源</span>
            </template>
        </template>
    </a-table>
</template>
<script>
    import { defineComponent,computed} from 'vue';
    import { usePagination } from 'vue-request';
    import {getDirectDistributionOrderList} from '@/api/distribution'
    export default defineComponent({
        setup() {
            //table
            const columns = [
                {
                    title: '企业名称',
                    dataIndex: 'organization_name',
                    align: 'left',
                },
                {
                    title: '姓名',
                    dataIndex: 'name',
                    align: 'left',
                },
                {
                    title: '手机号',
                    dataIndex: 'mobile',
                    align: 'left',
                },
                {
                    title: '内容',
                    dataIndex: 'content',
                    align: 'left',
                    show: true,
                },
                {
                    title: '支付金额',
                    dataIndex: 'pay_price',
                    align: 'left',
                },
                {
                    title: '支付时间',
                    dataIndex: 'paytime',
                    align: 'left',
                },
                {
                    title: '订单收益',
                    dataIndex: 'money',
                    align: 'left',
                },
            ];

            const queryData = params => {
                return getDirectDistributionOrderList(params).then((res) => {
                    return res.result;
                })
            };

            const {
                data,
                run,
                loading,
                current,
                pageSize,
                refresh
            } = usePagination(queryData);

            const dataSource = computed(() => data.value ? data.value.data : []);

            const pagination = computed(() => (
                {
                    total: data.value ? data.value.totalCount : '',
                    current: current.value,
                    pageSize: pageSize.value,
                    showTotal:(total) => `共 ${total} 条`,
                    pageSizeOptions:['10', '20', '30', '40', '50', '100'],
                    showSizeChanger:true,
                }
            ));

            const handleTableChange = (pag, filters, sorter) => {
                run({
                    current:pag.current,
                    pageSize:pag.pageSize,
                })
            }
            
            return {
                queryData,
                columns,
                dataSource,

                pagination,
                loading,
                handleTableChange,
            }
        }
    })
</script>