<template>
    <a-config-provider :locale="zhCN">
        <a-layout hasSider style="overflow: hidden;height:100vh">
            <a-layout-sider :width="!userStore.isCollapse ? 200 : 74" class="flexible-left">
                <div class="sidebar" :style="$common.isElectron() && $common.isMac() ? 'padding-top:50px' : 'padding-top:30px'">
                    <div class="sidebar-header" style="-webkit-app-region: no-drag">
                        <div class="personal-container">
                            <div class="avatar">
                                <a-dropdown :trigger="['click']" placement="rightBottom">
                                    <a-avatar :size="!userStore.isCollapse ? 52 : 40" :src="userInfo['avatar'] ? $common.cdnurl(userInfo['avatar'],true) : ''" style="color: #f56a00; background-color: #fde3cf">
                                        {{userInfo['name'].substring(0,1)}}
                                    </a-avatar>
                                    <template #overlay>
                                        <a-menu style="width:270px">
                                            <a-menu-item @click="profileModal">
                                                <a-list-item>
                                                    <a-list-item-meta>
                                                        <template #avatar>
                                                            <a-avatar :size="46" :src="userInfo['avatar'] ? $common.cdnurl(userInfo['avatar'],true) : ''" style="color: #f56a00; background-color: #fde3cf">
                                                                {{userInfo['name'].substring(0,1)}}
                                                            </a-avatar>
                                                        </template>
                                                        <template #title>
                                                            <span style="margin-right: 5px;">{{ userInfo['name']}}</span>
                                                            <edit-outlined />
                                                        </template>
                                                        <template #description>
                                                            <div style="display: flex;">
                                                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-right: 8px;">
                                                                    <span>{{ userInfo['organization_name']}}</span>
                                                                </div>
                                                                <a-tag v-if="userInfo['is_pay'] === 0" style="margin-right:0" color="#c3c6cb">免费版</a-tag>
                                                                <a-tag v-if="userInfo['is_pay'] === 1" style="margin-right:0" color="#ff5603">企业版VIP</a-tag>
                                                            </div>
                                                        </template>
                                                    </a-list-item-meta>
                                                </a-list-item>
                                            </a-menu-item>
                                            <a-menu-divider />
                                            <!-- <a-sub-menu>
                                                <template #icon>
                                                    <desktop-outlined style="font-size: 13px;"/>
                                                </template>
                                                <template #title>
                                                    <span style="margin-left: 2px;">主题模式</span>
                                                </template>
                                                <a-menu-item>浅色</a-menu-item>
                                                <a-menu-item>深色</a-menu-item>
                                                <a-menu-item>跟随系统</a-menu-item>
                                            </a-sub-menu> -->
                                            <a-menu-item @click="accountSettingModal" style="padding:15px;">
                                                <setting-outlined />
                                                <span style="margin-left: 10px;">账号设置</span>
                                            </a-menu-item>
                                            <a-menu-item @click="accountModal" style="padding:15px;">
                                                <pay-circle-outlined />
                                                <span style="margin-left: 10px;">账户管理</span>
                                            </a-menu-item>
                                            <a-menu-item v-if="$common.isElectron()" @click="softwareUpdateModal('default')" style="padding:15px;">
                                                <sync-outlined />
                                                <span style="margin-left: 10px;">检查更新</span>
                                            </a-menu-item>
                                            <a-menu-item @click="clickLogout" style="padding:12px;">
                                                <poweroff-outlined style="margin-left: 3.2px;"/>
                                                <span style="margin-left: 9px;">退出登录</span>
                                            </a-menu-item>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </div>

                            
                            <div v-if="!userStore.isCollapse" style="margin-top: 10px;padding: 0 12px;font-size: 13px;font-weight: bold;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ userInfo['organization_name']}}</div>

                            <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'manage'" >
                                <div v-if="userInfo['expiration_status'] === 1" >
                                    <a-button v-if="!userStore.isCollapse" @click="upgradeVipModal" size="small" type="primary" style="margin-top: 10px;">升级VIP</a-button>
                                    <div v-else class="vip-text" @click="upgradeVipModal">升级VIP</div> 
                                </div>
                                <div v-if="userInfo['expiration_status'] === 2 || userInfo['expiration_status'] === 3" class="vip-text">
                                    <span v-if="!userStore.isCollapse" style="margin-right: 8px;">
                                        <span v-if="userInfo['expiration_status'] === 2">有效期至 {{$moment(userInfo['expirationtime']).format('YYYY-MM-DD')}}</span>
                                        <span v-if="userInfo['expiration_status'] === 3">企业版VIP 已过期</span>
                                    </span>
                                    <span style="color: #f56a00;"  @click="upgradeVipModal">续费</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="!userStore.isCollapse" class="expand-layout" style="-webkit-app-region: no-drag;">
                        <div class="sidebar-content">
                            <a-menu mode="inline" v-model:selectedKeys="selectedKeys" :openKeys="openKeys"  @openChange="onOpenChange" style="background-color: #f9fafb;border-right:0">
                                <a-sub-menu v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'edit' || userInfo['rules'] === 'manage'" key="myProductLibrary">
                                    <template #icon>
                                        <icon>
                                            <template #component>
                                                <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                    <path d="M882.090667 384l-60.928-213.333333H202.837333L141.909333 384H426.666667v42.666667a85.333333 85.333333 0 1 0 170.666666 0V384h284.757334zM128 469.333333v384h768v-384h-218.709333a170.752 170.752 0 0 1-330.581334 0H128z m757.504-384L981.333333 420.693333V938.666667H42.666667V420.693333L138.496 85.333333h747.008z"></path>
                                                </svg>
                                            </template>
                                        </icon>
                                    </template>
                                    <template #title>我的产品库</template>
                                    <router-link to="/note/index">
                                        <a-menu-item class="list-item" key="/note/index">
                                            <template #icon>
                                                <unordered-list-outlined />
                                            </template>
                                            <div class="menu-title">
                                                <span>产品列表</span>
                                            </div>
                                        </a-menu-item>
                                    </router-link>

                                    <router-link to="/category/index">
                                        <a-menu-item class="list-item" key="/category/index">
                                            <template #icon>
                                                <appstore-outlined />
                                            </template>
                                            <div class="menu-title">
                                                <span>分类列表</span>
                                            </div>
                                        </a-menu-item>
                                    </router-link>

                                    <router-link to="/brand/index">
                                        <a-menu-item class="list-item" key="/brand/index">
                                            <template #icon>
                                                <trademark-outlined/>
                                            </template>
                                            <div class="menu-title">
                                                <span>品牌列表</span>
                                            </div>
                                        </a-menu-item>
                                    </router-link>

                                    <router-link to="/supplier/index">
                                        <a-menu-item class="list-item" key="/supplier/index">
                                            <template #icon>
                                                <user-switch-outlined />
                                            </template>
                                            <div class="menu-title">
                                                <span>供应商列表</span>
                                            </div>
                                        </a-menu-item>
                                    </router-link>
                                </a-sub-menu>
                                <router-link v-else to="/note/index">
                                    <a-menu-item class="list-item" key="/note/index">
                                        <template #icon>
                                            <icon>
                                                <template #component>
                                                    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                        <path d="M882.090667 384l-60.928-213.333333H202.837333L141.909333 384H426.666667v42.666667a85.333333 85.333333 0 1 0 170.666666 0V384h284.757334zM128 469.333333v384h768v-384h-218.709333a170.752 170.752 0 0 1-330.581334 0H128z m757.504-384L981.333333 420.693333V938.666667H42.666667V420.693333L138.496 85.333333h747.008z"></path>
                                                    </svg>
                                                </template>
                                            </icon>
                                        </template>
                                        <div class="menu-title">
                                            <span>我的产品库</span>
                                        </div>
                                    </a-menu-item>
                                </router-link>
            
                                <router-link to="/note_folder/index">
                                    <a-menu-item class="list-item" key="/note_folder/index">
                                        <template #icon>
                                            <folder-outlined />
                                        </template>
                                        <div class="menu-title">
                                            <span>我的笔记夹</span>
                                        </div>
                                    </a-menu-item>
                                </router-link>

                                <a-sub-menu v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'edit' || userInfo['rules'] === 'manage'" key="myProductCircle">
                                    <template #icon>
                                        <icon>
                                            <template #component>
                                                <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                    <path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 938.666667a426.666667 426.666667 0 1 1 426.666667-426.666667 426.666667 426.666667 0 0 1-426.666667 426.666667z"></path>
                                                    <path d="M665.6 305.92L406.186667 375.893333a42.666667 42.666667 0 0 0-30.293334 29.866667L306.346667 665.6a42.666667 42.666667 0 0 0 11.093333 42.666667 42.666667 42.666667 0 0 0 29.866667 12.8 32 32 0 0 0 11.093333 0l259.84-69.546667a42.666667 42.666667 0 0 0 30.293333-29.866667l69.546667-259.84a42.666667 42.666667 0 0 0-52.48-52.48z m-49.066667 101.546667l-31.573333 115.626666-85.333333-85.333333z m-208.64 208.64l31.146667-116.906667 42.666667 42.666667 42.666666 42.666666z"></path>
                                                </svg>
                                            </template>
                                        </icon>
                                    </template>
                                    <template #title>
                                        我的产品圈<a-badge :count="userStore.badgeSubCount" :offset="[8,-2]" />
                                    </template>
                                    <router-link to="/share_note/index">
                                        <a-menu-item class="list-item" :key="selectedKeys[0] == '/share_note/receiver'? '/share_note/receiver' : '/share_note/sender'">
                                            <template #icon>
                                                <share-alt-outlined />
                                            </template>
                                            <div class="menu-title">
                                                <span>共享列表</span>
                                            </div>
                                        </a-menu-item>
                                    </router-link>
                                    <router-link to="/share_group/index">
                                        <a-menu-item class="list-item" key="/share_group/index">
                                            <template #icon>
                                                <group-outlined />
                                            </template>
                                            <div class="menu-title">
                                                <span>分组共享</span>
                                            </div>
                                        </a-menu-item>
                                    </router-link>
                                </a-sub-menu>
                                <router-link v-else to="/share_note/index">
                                    <a-menu-item class="list-item" :key="selectedKeys[0] == '/share_note/receiver'? '/share_note/receiver' : '/share_note/sender'">
                                        <template #icon>
                                            <icon>
                                                <template #component>
                                                    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                        <path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 938.666667a426.666667 426.666667 0 1 1 426.666667-426.666667 426.666667 426.666667 0 0 1-426.666667 426.666667z"></path>
                                                        <path d="M665.6 305.92L406.186667 375.893333a42.666667 42.666667 0 0 0-30.293334 29.866667L306.346667 665.6a42.666667 42.666667 0 0 0 11.093333 42.666667 42.666667 42.666667 0 0 0 29.866667 12.8 32 32 0 0 0 11.093333 0l259.84-69.546667a42.666667 42.666667 0 0 0 30.293333-29.866667l69.546667-259.84a42.666667 42.666667 0 0 0-52.48-52.48z m-49.066667 101.546667l-31.573333 115.626666-85.333333-85.333333z m-208.64 208.64l31.146667-116.906667 42.666667 42.666667 42.666666 42.666666z"></path>
                                                    </svg>
                                                </template>
                                            </icon>
                                        </template>
                                        <div class="menu-title">
                                            <span>我的产品圈<a-badge :count="userStore.badgeSubCount" :offset="[8,-2]" /></span>
                                        </div>
                                    </a-menu-item>
                                </router-link>
                            </a-menu>
                        </div>

                        <div class="sidebar-footer">
                            <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'manage'" >
                                <div class="memberPayView" v-if="userInfo['expiration_status'] === 1 || userInfo['expiration_status'] === 3">
                                    <div class="memberPay">
                                        <div class="expiringTip-module_tip">
                                            <p class="expiringTip-module_title" style="text-align: left;" v-if="userInfo['expiration_status'] === 1">当前为企业免费版本，可添加产品{{userInfo['note_num']}}个，开通企业成员子账号{{userInfo['user_num']}}个（企业VIP无限制）</p>
                                            <p class="expiringTip-module_title" v-if="userInfo['expiration_status'] ===3">企业版VIP 已过期</p>
                                            <div class="expiringTip-module_button" @click="upgradeVipModal">
                                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="larkui-icon larkui-icon-update"><path d="M10 2.188a7.813 7.813 0 1 1 0 15.625 7.813 7.813 0 0 1 0-15.625Zm.32 3.254a.5.5 0 0 0-.7.104L6.972 9.122a.4.4 0 0 0 .322.638h1.042V14a.5.5 0 0 0 .5.5H11.3a.5.5 0 0 0 .5-.5V9.76h.912a.4.4 0 0 0 .323-.636l-2.61-3.575a.5.5 0 0 0-.107-.107Z" fill="#ECB604" fill-rule="nonzero"></path></svg>
                                                <span v-if="userInfo['expiration_status'] === 1">立即升级企业VIP</span>
                                                <span v-if="userInfo['expiration_status'] === 3">立即续费</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="sidebar-footer-links">
                                <div class="sidebar-footer-links-item" @click="organizationListModel">
                                    <partition-outlined style="color:#7a8599"/>
                                    <div class="sidebar-footer-links-item-text">企业切换</div>
                                </div>
                                <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'manage'" class="sidebar-footer-links-item" @click="teamsListModel">
                                    <team-outlined style="color:#7a8599"/>
                                    <div class="sidebar-footer-links-item-text">企业成员</div>
                                </div>
                                <div class="sidebar-footer-links-item" @click="siteModal">
                                    <mobile-outlined style="color:#7a8599"/>
                                    <div class="sidebar-footer-links-item-text">手机推品</div>
                                </div>
                                <!-- <div class="sidebar-footer-links-item" @click="printModal">
                                    <printer-outlined style="color:#7a8599"/>
                                    <div class="sidebar-footer-links-item-text">打印管理</div>
                                </div> -->
                                <!-- <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'edit' || userInfo['rules'] === 'manage'" class="sidebar-footer-links-item" @click="erpModal">
                                    <api-outlined style="color:#7a8599"/>
                                    <div class="sidebar-footer-links-item-text">对接ERP</div>
                                </div> -->
                            </div>
  
                            <div class="sidebar-footer-view">
                                <div class="split-line-linear-gray"></div>
                                <div class="view-container">
                                    <a-tooltip placement="top">
                                        <div @click="clickCollapse(false)" class="btn-column">
                                            <one-to-one-outlined style="font-size: 16px;color:#7a8599" />
                                        </div>
                                        <template #title>展开</template>
                                    </a-tooltip>
                                    <a-tooltip placement="top">
                                        <div @click="clickCollapse(true)" class="btn-column" style="margin-left: 10px;font-size: 16px;color:#7a8599">
                                            <profile-outlined />
                                        </div>
                                        <template #title>收起</template>
                                    </a-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="userStore.isCollapse" class="collapse-layout" style="-webkit-app-region: no-drag;">
                        <div class="sidebar-collapse-content">
                            <a v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'edit' || userInfo['rules'] === 'manage'">
                                <a-dropdown :trigger="['hover']" placement="rightBottom">
                                    <div :class="['sidebar-collapse-content-item',selectedKeys[0] == '/note/index' || selectedKeys[0] == '/category/index' || selectedKeys[0] == '/brand/index' || selectedKeys[0] == '/supplier/index' ? 'selected':'']">
                                        <icon class="icon">
                                            <template #component>
                                                <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                    <path d="M882.090667 384l-60.928-213.333333H202.837333L141.909333 384H426.666667v42.666667a85.333333 85.333333 0 1 0 170.666666 0V384h284.757334zM128 469.333333v384h768v-384h-218.709333a170.752 170.752 0 0 1-330.581334 0H128z m757.504-384L981.333333 420.693333V938.666667H42.666667V420.693333L138.496 85.333333h747.008z"></path>
                                                </svg>
                                            </template>
                                        </icon>
                                    </div>
                                    <template #overlay>
                                        <a-menu v-model:selectedKeys="selectedKeys" style="width:160px">
                                            <router-link to="/note/index">
                                                <a-menu-item style="padding: 8px 12px;" key="/note/index">
                                                    <template #icon>
                                                        <unordered-list-outlined style="font-size: 14px;"/>
                                                    </template>
                                                    <div class="menu-title">
                                                        <span>产品列表</span>
                                                    </div>
                                                </a-menu-item>
                                            </router-link>

                                            <router-link to="/category/index">
                                                <a-menu-item style="padding: 8px 12px;"  key="/category/index">
                                                    <template #icon>
                                                        <appstore-outlined style="font-size: 14px;"/>
                                                    </template>
                                                    <div class="menu-title">
                                                        <span>分类列表</span>
                                                    </div>
                                                </a-menu-item>
                                            </router-link>

                                            <router-link to="/brand/index">
                                                <a-menu-item style="padding: 8px 12px;"  key="/brand/index">
                                                    <template #icon>
                                                        <trademark-outlined style="font-size: 14px;"/>
                                                    </template>
                                                    <div class="menu-title">
                                                        <span>品牌列表</span>
                                                    </div>
                                                </a-menu-item>
                                            </router-link>

                                            <router-link to="/supplier/index">
                                                <a-menu-item style="padding: 8px 12px;"  key="/supplier/index">
                                                    <template #icon>
                                                        <user-switch-outlined style="font-size: 14px;"/>
                                                    </template>
                                                    <div class="menu-title">
                                                        <span>供应商列表</span>
                                                    </div>
                                                </a-menu-item>
                                            </router-link>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </a>
                            <router-link v-else to="/note/index">
                                <a-tooltip placement="right">
                                    <div :class="['sidebar-collapse-content-item',selectedKeys[0] == '/note/index' ? 'selected':'']">
                                        <icon class="icon">
                                            <template #component>
                                                <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                    <path d="M882.090667 384l-60.928-213.333333H202.837333L141.909333 384H426.666667v42.666667a85.333333 85.333333 0 1 0 170.666666 0V384h284.757334zM128 469.333333v384h768v-384h-218.709333a170.752 170.752 0 0 1-330.581334 0H128z m757.504-384L981.333333 420.693333V938.666667H42.666667V420.693333L138.496 85.333333h747.008z"></path>
                                                </svg>
                                            </template>
                                        </icon>
                                    </div>
                                    <template #title>我的产品库</template>
                                </a-tooltip>
                            </router-link>
                    

                            <router-link to="/note_folder/index">
                                <a-tooltip placement="right">
                                    <div :class="['sidebar-collapse-content-item',selectedKeys[0] == '/note_folder/index' ? 'selected':'']">
                                        <folder-outlined class="icon"/>
                                    </div>
                                    <template #title>我的笔记夹</template>
                                </a-tooltip>
                            </router-link>

                            <a v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'edit' || userInfo['rules'] === 'manage'"  >
                                <a-dropdown :trigger="['hover']" placement="rightBottom">
                                    <div :class="['sidebar-collapse-content-item',selectedKeys[0] == '/share_note/receiver' || selectedKeys[0] == '/share_note/sender' || selectedKeys[0] == '/share_group/index' ? 'selected':'']">
                                        <a-badge :count="userStore.badgeSubCount" :offset="[10,-7]">
                                            <icon class="icon">
                                                <template #component>
                                                    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                        <path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 938.666667a426.666667 426.666667 0 1 1 426.666667-426.666667 426.666667 426.666667 0 0 1-426.666667 426.666667z"></path>
                                                        <path d="M665.6 305.92L406.186667 375.893333a42.666667 42.666667 0 0 0-30.293334 29.866667L306.346667 665.6a42.666667 42.666667 0 0 0 11.093333 42.666667 42.666667 42.666667 0 0 0 29.866667 12.8 32 32 0 0 0 11.093333 0l259.84-69.546667a42.666667 42.666667 0 0 0 30.293333-29.866667l69.546667-259.84a42.666667 42.666667 0 0 0-52.48-52.48z m-49.066667 101.546667l-31.573333 115.626666-85.333333-85.333333z m-208.64 208.64l31.146667-116.906667 42.666667 42.666667 42.666666 42.666666z"></path>
                                                    </svg>
                                                </template>
                                            </icon>
                                        </a-badge>
                                    </div>
                                    <template #overlay>
                                        <a-menu v-model:selectedKeys="selectedKeys" style="width:160px">
                                            <router-link to="/share_note/index">
                                                <a-menu-item style="padding: 8px 12px;" :key="selectedKeys[0] == '/share_note/receiver'? '/share_note/receiver' : '/share_note/sender'">
                                                    <template #icon>
                                                        <share-alt-outlined style="font-size: 14px;"/>
                                                    </template>
                                                    <div class="menu-title">
                                                        <span>共享列表</span>
                                                    </div>
                                                </a-menu-item>
                                            </router-link>
                                            <router-link to="/share_group/index">
                                                <a-menu-item style="padding: 8px 12px;" key="/share_group/index">
                                                    <template #icon>
                                                        <group-outlined style="font-size: 14px;"/>
                                                    </template>
                                                    <div class="menu-title">
                                                        <span>分组共享</span>
                                                    </div>
                                                </a-menu-item>
                                            </router-link>
                                        </a-menu>
                                    </template>
                                </a-dropdown>
                            </a>
                            <router-link v-else to="/share_note/index">
                                <a-tooltip placement="right">
                                    <div :class="['sidebar-collapse-content-item',selectedKeys[0] == '/share_note/receiver' || selectedKeys[0] == '/share_note/sender' || selectedKeys[0] == '/share_group/index' ? 'selected':'']">
                                        <icon class="icon">
                                            <template #component>
                                                <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024">
                                                    <path d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m0 938.666667a426.666667 426.666667 0 1 1 426.666667-426.666667 426.666667 426.666667 0 0 1-426.666667 426.666667z"></path>
                                                    <path d="M665.6 305.92L406.186667 375.893333a42.666667 42.666667 0 0 0-30.293334 29.866667L306.346667 665.6a42.666667 42.666667 0 0 0 11.093333 42.666667 42.666667 42.666667 0 0 0 29.866667 12.8 32 32 0 0 0 11.093333 0l259.84-69.546667a42.666667 42.666667 0 0 0 30.293333-29.866667l69.546667-259.84a42.666667 42.666667 0 0 0-52.48-52.48z m-49.066667 101.546667l-31.573333 115.626666-85.333333-85.333333z m-208.64 208.64l31.146667-116.906667 42.666667 42.666667 42.666666 42.666666z"></path>
                                                </svg>
                                            </template>
                                        </icon>
                                    </div>
                                    <template #title>我的产品圈</template>
                                </a-tooltip>
                            </router-link>
                        </div>
                        <div class="sidebar-collapse-footer"> 
                            <div>
                                <a-tooltip placement="right">
                                    <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'manage'" >
                                        <div v-if="userInfo['expiration_status'] === 1" class="sidebar-collapse-content-item" style="margin: 12px auto;border: 1px solid #f6e1ac;width: 24px;height: 24px;border-radius: 24px;">
                                            <svg style="position: relative;left: -1px;top: -1px;" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="larkui-icon larkui-icon-update"><path d="M10 2.188a7.813 7.813 0 1 1 0 15.625 7.813 7.813 0 0 1 0-15.625Zm.32 3.254a.5.5 0 0 0-.7.104L6.972 9.122a.4.4 0 0 0 .322.638h1.042V14a.5.5 0 0 0 .5.5H11.3a.5.5 0 0 0 .5-.5V9.76h.912a.4.4 0 0 0 .323-.636l-2.61-3.575a.5.5 0 0 0-.107-.107Z" fill="#ECB604" fill-rule="nonzero"></path></svg>
                                        </div>
                                    </div>
                                    <template #title>免费权益生效中<span  @click="upgradeVipModal" style="margin-left: 10px;color: #f56a00;cursor: pointer;">立即升级企业VIP</span></template>
                                </a-tooltip>
                                <a-tooltip placement="right">
                                    <div class="sidebar-collapse-content-item" style="width: 18px;height: 18px;margin: 12px auto;" @click="organizationListModel">
                                        <partition-outlined style="color:#7a8599"/>
                                    </div>
                                    <template #title>企业切换</template>
                                </a-tooltip>
                                <a-tooltip placement="right">
                                    <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'manage'" class="sidebar-collapse-content-item" style="width: 18px;height: 18px;margin: 12px auto;" @click="teamsListModel">
                                        <team-outlined style="color:#7a8599"/>
                                    </div>
                                    <template #title>企业成员</template>
                                </a-tooltip>
                                <a-tooltip placement="right">
                                    <div class="sidebar-collapse-content-item" style="width: 18px;height: 18px;margin: 12px auto;" @click="siteModal">
                                        <mobile-outlined style="color:#7a8599"/>
                                    </div>
                                    <template #title>手机推品</template>
                                </a-tooltip>
                                <!-- <a-tooltip placement="right">
                                    <div class="sidebar-collapse-content-item" style="width: 18px;height: 18px;margin: 12px auto;" @click="printModal">
                                        <printer-outlined style="color:#7a8599"/>
                                    </div>
                                    <template #title>打印管理</template>
                                </a-tooltip> -->
                                <!-- <a-tooltip placement="right">
                                    <div v-if="userInfo['rules'] === '*' || userInfo['rules'] === 'edit' || userInfo['rules'] === 'manage'" class="sidebar-collapse-content-item" style="width: 18px;height: 18px;margin: 12px auto;" @click="erpModal">
                                        <api-outlined style="color:#7a8599"/>
                                    </div>
                                    <template #title>对接ERP</template>
                                </a-tooltip> -->
                            </div>
                            <div class="split-line-linear-gray"></div>
                            <div class="sidebar-collapse-content-item sidebar-collapse-footer-view">
                                <div class="view-container">
                                    <a-tooltip placement="right">
                                        <div @click="clickCollapse(false)" class="btn-column">
                                            <one-to-one-outlined style="font-size: 16px;color:#7a8599" />
                                        </div>
                                        <template #title>展开</template>
                                    </a-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-layout-sider>

            <a-layout style="box-shadow: -2px 0 4px rgb(0 0 0 / 2%);">
                <a-layout-header style="height: 50px;line-height: 50px;background-color: #fff;padding:0 20px;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #f0f0f0;z-index: 99;">
                    <div style="display: flex;">
                        
                    </div>
                    <div style="display: flex;-webkit-app-region: no-drag">
                        <div v-if="userInfo['distribution_level'] == 2" @click="shareholderAccountModal" style="font-size: 13px;">
                            <property-safety-outlined style="margin-right: 5px;"/>股东分红
                        </div>
                        <div @click="distributionAccountModal" style="margin-left: 15px;font-size: 13px;">
                            <share-alt-outlined style="margin-right: 5px;"/>邀请奖励
                        </div>
                        <div @click="$common.openUrl('https://www.yuque.com/chenpeng-x7zsz/tlbuso?#')" style="margin-left: 15px;font-size: 13px;">
                            <book-outlined  style="margin-right: 5px;"/>使用教程
                        </div>
                        <div v-if="!$common.isElectron()" @click="$common.openUrl('https://www.maoxiubiji.com/download')"  style="margin-left: 15px;font-size: 13px;">
                            <download-outlined style="margin-right: 5px;"/>客户端下载
                        </div>
                    </div>
                </a-layout-header>
            
                <a-layout-content style="background-color: #fff;-webkit-app-region: no-drag">
                    <router-view />
                </a-layout-content>
            </a-layout>
        </a-layout>

        <!-- 升级VIP -->
        <upgrade-vip-modal v-if="upgradeVipVisible" :visible="upgradeVipVisible" @cancel="upgradeVipVisible = false" ></upgrade-vip-modal>

        <!-- 个人资料 -->
        <profile-modal v-if="profileVisible" :visible="profileVisible" @cancel="profileVisible = false" ></profile-modal>

        <!-- 账号设置 -->
        <account-setting-modal v-if="accountSettingVisible" :visible="accountSettingVisible" @cancel="accountSettingVisible = false" ></account-setting-modal>

        <!-- 账户管理 -->
        <account-modal v-if="accountVisible" :visible="accountVisible" @cancel="accountVisible = false"></account-modal>

        <!-- 股东账户 -->
        <shareholder-account-modal v-if="shareholderAccountVisible" :visible="shareholderAccountVisible" @cancel="shareholderAccountVisible = false"></shareholder-account-modal>

        <!-- 分销账户 -->
        <distribution-account-modal v-if="distributionAccountVisible" :visible="distributionAccountVisible" @cancel="distributionAccountVisible = false"></distribution-account-modal>

        <!-- 企业切换 -->
        <organization-list-modal v-if="organizationListVisible" :visible="organizationListVisible" @cancel="organizationListVisible = false" ></organization-list-modal>

        <!-- 成员管理 -->
        <teams-list-modal v-if="teamsListVisible" :visible="teamsListVisible" @cancel="teamsListVisible = false" ></teams-list-modal>

        <!-- 手机产品库 -->
        <site-modal v-if="siteVisible" :visible="siteVisible" @cancel="siteVisible = false"></site-modal>

        <!-- 打印管理 -->
        <print-modal v-if="printVisible" :visible="printVisible" @cancel="printVisible = false"></print-modal>

        <!-- 对接ERP -->
        <erp-modal v-if="erpVisible" :visible="erpVisible" @cancel="erpVisible = false"></erp-modal>


        <!-- 绑定微信 -->
        <bind-wechat-modal v-if="bindWechatVisible" :visible="bindWechatVisible" @cancel="bindWechatVisible = false" :closable="false"></bind-wechat-modal>

        <!-- 检查更新 -->
        <software-update-modal v-if="softwareUpdateVisible" :visible="softwareUpdateVisible" @cancel="softwareUpdateVisible = false" :data="latestVersion"></software-update-modal>

        <!-- 悬浮按钮 -->
        <float-button></float-button>
    </a-config-provider>
</template>
<script>
import { defineComponent, ref,reactive,toRefs,computed,h, watch ,onMounted,onUnmounted} from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('en');

import Icon, {EditOutlined,TeamOutlined ,SettingOutlined,PoweroffOutlined,AppstoreOutlined,TrademarkOutlined,UserSwitchOutlined,FolderOutlined,OneToOneOutlined,ProfileOutlined,DesktopOutlined,PartitionOutlined,PayCircleOutlined, SyncOutlined,DownloadOutlined,MobileOutlined,NodeExpandOutlined,PropertySafetyOutlined,BookOutlined,ShareAltOutlined,FileProtectOutlined,UnorderedListOutlined,GroupOutlined,ApiOutlined,PrinterOutlined} from '@ant-design/icons-vue';

import { useRouter } from 'vue-router';

import { Modal } from 'ant-design-vue';

import useUser from '@/stores/user';

import upgradeVipModal from '@/components/upgradeVipModal';
import profileModal from '@/components/profileModal';
import accountSettingModal from '@/components/accountSetting/accountSettingModal';
import accountModal from '@/components/account/accountModal';
import shareholderAccountModal from '@/components/shareholderAccount/shareholderAccountModal';
import distributionAccountModal from '@/components/distributionAccount/distributionAccountModal';
import organizationListModal from '@/components/organization/organizationListModal';
import teamsListModal from '@/components/teams/teamsListModal';
import siteModal from '@/components/site/siteModal';
import printModal from '@/components/print/printModal';
import erpModal from '@/components/erp/erpModal';
import bindWechatModal from '@/components/accountSetting/bindWechatModal';
import softwareUpdateModal from '@/components/softwareUpdateModal';
import floatButton from '@/components/floatButton';

import common from '@/utils/common.js';

import config from '../../package.json';
import { getLatestVersion} from '@/api/version';

export default defineComponent({
    components: {
        Icon,
        EditOutlined,
        TeamOutlined,
        SettingOutlined,
        PoweroffOutlined,
        AppstoreOutlined,
        TrademarkOutlined,
        UserSwitchOutlined,
        FolderOutlined,
        OneToOneOutlined,
        ProfileOutlined,
        DesktopOutlined,
        PartitionOutlined,
        PayCircleOutlined,
        SyncOutlined,
        DownloadOutlined,
        MobileOutlined,
        NodeExpandOutlined,
        PropertySafetyOutlined,
        BookOutlined,
        ShareAltOutlined,
        FileProtectOutlined,
        UnorderedListOutlined,
        GroupOutlined,
        ApiOutlined,
        PrinterOutlined,
        
        upgradeVipModal,
        profileModal,
        accountSettingModal,
        accountModal,
        shareholderAccountModal,
        distributionAccountModal,
        organizationListModal,
        teamsListModal,
        siteModal,
        printModal,
        erpModal,
        bindWechatModal,
        softwareUpdateModal,
        floatButton
    },
    setup() {
        const router = useRouter();

        const userStore = useUser();

        const userInfo = computed(() => userStore.userInfo);

        const bindWechatVisible = ref(false);

        //轮询获取新申请徽标数
        let timer;
        const interval = () => {
            clearInterval(timer)
            timer = setInterval(() => {
                userStore.GetApplyBadgeSubCount();
            }, 2000)
        }

        onMounted(() => {
            common.showMainWindow();

            //绑定微信
            if(!userInfo.value.openid){
                bindWechatVisible.value = true;
            }

            //检查更新
            softwareUpdateModal('auto');

            //调用轮询获取新申请徽标数
            interval();
        })

        onUnmounted(() => {
            // 离开页面销毁定时器
            clearInterval(timer)
        })

        const state = reactive({
            rootSubmenuKeys: ['myProductLibrary','myProductCircle'],
            openKeys: ['myProductLibrary'],
            selectedKeys: [],
        });

        const updateMenu = values => {
            const routes = router.currentRoute.value.path;
        
            if(routes == '/share_note/index' || routes == '/share_note/receiver' || routes == '/share_note/sender' || routes == '/share_group/index'){
                state.openKeys = ['myProductCircle'];
            }else if(routes == '/note/index' || routes == '/category/index' || routes == '/brand/index' || routes == '/supplier/index'){
                state.openKeys = ['myProductLibrary'];
            }
            state.selectedKeys = [routes];
    
        }

        watch(() => updateMenu(), val => { });

        const onOpenChange = openKeys => {
            const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);
            if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                state.openKeys = openKeys;
            } else {
                state.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        }

       
        //升级会员
        const upgradeVipVisible = ref(false);
        const upgradeVipModal = () => {
            upgradeVipVisible.value = true;
        }

        //个人资料
        const profileVisible = ref(false);
        const profileModal = () => {
            profileVisible.value = true;
        }

        //账号设置
        const accountSettingVisible = ref(false);
        const accountSettingModal = () => {
            accountSettingVisible.value = true;   
        }

        //账户管理
        const accountVisible = ref(false);
        const accountModal = () => {
            accountVisible.value = true;
        }

        //股东账户
        const shareholderAccountVisible = ref(false);
        const shareholderAccountModal = () => {
            shareholderAccountVisible.value = true;
        }

        //分销账户
        const distributionAccountVisible = ref(false);
        const distributionAccountModal = () => {
            distributionAccountVisible.value = true;
        }

        //企业切换
        const organizationListVisible = ref(false);
        const organizationListModel = () => {
            organizationListVisible.value = true;  
        }

        //成员管理
        const teamsListVisible = ref(false);
        const teamsListModel = () => {
            teamsListVisible.value = true;   
        }

        //手机产品库
        const siteVisible = ref(false);
        const siteModal = () => {
            siteVisible.value = true;
        }

        //打印管理
        const printVisible = ref(false);
        const printModal = () => {
            printVisible.value = true;
        }

        //对接ERP
        const erpVisible = ref(false);
        const erpModal = () => {
            if(userInfo.value.expiration_status == 1 || userInfo.value.expiration_status == 3){
                if(userInfo.value.rules === '*' || userInfo.value.rules === 'manage'){
                    upgradeVipVisible.value = true;
                }else{
                    message.error('请联系管理员升级企业版VIP');
                }
            }else{
                erpVisible.value = true;
            }
        }

        //收缩菜单栏 
        const clickCollapse = (value) => {
            userStore.SetCollapse(value);
        };

        //检查更新
        const softwareUpdateVisible = ref(false);
        const latestVersion = ref();
        const softwareUpdateModal = (type = 'default') => {
            if(common.isElectron()) {
                const platformType = process.platform === 'darwin' ? '20' : '10';
                getLatestVersion({type:platformType}).then((res) => {
                    //版本比对
                    if(res.status == 1 && res.result && common.compare(res.result.newversion,config.version)){
                        softwareUpdateVisible.value = true;
                        latestVersion.value = res.result
                    }else{
                        if(type == 'default'){
                            Modal.warning({
                                title: '您使用的是最新版本',
                                content: '猫嗅笔记'+ config.version + '已是最新版本',
                                centered: true,
                                icon:h('span',{style:{float:'left',marginRight:'10px'}},[h('img',{src:require("@/assets/logo.png"),style:{
                                    width:'50px',height:'50px'
                                }})]),
                            })
                        }
                    }
                })
            }
        }

        //退出登录
        const clickLogout = () => {
            Modal.confirm({
                title: '确定要退出登录吗？',
                content: '退出登录后，不会删除任何信息',
                cancelText: '取消',
                okText: '确定',
                centered: true,
                onOk: () => {
                    userStore.Logout().then((res) => {
                        router.push('/user/login');

                        common.showLoginWindow();
                    });
                }
            })
        };

        return {
            zhCN,

            userStore,
            userInfo,

            ...toRefs(state),
            onOpenChange,

            bindWechatVisible,

            upgradeVipVisible,
            upgradeVipModal,

            profileVisible,
            profileModal,

            accountSettingVisible,
            accountSettingModal,

            accountVisible,
            accountModal,

            shareholderAccountVisible,
            shareholderAccountModal,

            distributionAccountVisible,
            distributionAccountModal,

            organizationListVisible,
            organizationListModel,

            teamsListVisible,
            teamsListModel,

            siteVisible,
            siteModal,

            printVisible,
            printModal,

            erpVisible,
            erpModal,

            clickCollapse,

            softwareUpdateVisible,
            latestVersion,
            softwareUpdateModal,

            clickLogout,
        }
    }
});
</script>
<style lang="less" scoped>
.flexible-left {
    background-color: #f8f9fa;

    .sidebar {
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        line-height: 17px;
        height: 100%;
        border: none;
        font-style: normal;
        font-weight: 400;
        box-sizing: border-box;

        .sidebar-header {
            .personal-container {
                position: relative;
                width: 100%;
                text-align: center;

                .avatar {
                    cursor: pointer;
                }

                .vip-text{
                    margin-top: 5px;
                    font-size: 12px;
                    color: #7a8599;
                    cursor: pointer;
                }
            }
        }

        .expand-layout {
            position: relative;
            min-height: 360px;
            box-sizing: border-box;
            flex: 1;
            display: flex;
            flex-direction: column;

            .sidebar-content {
                flex: 1;
                overflow-y: auto;
                overflow-x: hidden;
                margin-top: 30px;
                padding-bottom: 10px;
                width: 100%;
                font-size: 14px;
            }

            .sidebar-footer {
                .sidebar-footer-links {
                    text-align: center;
                    .sidebar-footer-links-item {
                        width: 50%;
                        flex: 1 0 auto;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        .sidebar-footer-links-item-text {
                            display: inline-block;
                            margin-left: 5px;
                            line-height: 35px;
                            font-size: 13px;
                            color: #7a8599;
                            vertical-align: middle;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
                .sidebar-footer-view {
                    height: 52px;
                    line-height: 52px;
                    text-align: center;
                    overflow: hidden;
                    box-sizing: border-box;
                }

                .memberPayView{
                    padding: 12px;
                    .memberPay{
                        width: 175px;
                        height: 130px;
                        padding: 0 10px;
                        background-color: #fcf5e6;
                        border-radius: 8px;
                        border: 1px solid #f6e1ac;
                        display: flex;
                        align-items: center;
                    }
                    
                    .expiringTip-module_tip{
                        text-align: center;
                        width: 100%;
                    }

                    .expiringTip-module_title{
                        color: #333;
                        font-size: 12px;
                        font-weight: 500;
                        width: 100%;
                    }

                    .expiringTip-module_button{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                        margin-top: 12px;
                        width: 135px;
                        height: 30px;
                        border: 1px solid #f5d480;
                        background: #fff;
                        border-radius: 30px;
                        font-size: 12px;
                        text-align: center;
                        line-height: 30px;
                        color: #ecaa04;
                        font-weight: 500;
                        cursor: pointer;

                        span{
                            margin-left: 5px;
                        }
                    }
                }
               
            }
        }

        .collapse-layout {
            min-height: 360px;
            box-sizing: border-box;
            flex: 1;
            display: flex;
            flex-direction: column;
            .sidebar-collapse-content {
                flex: 1;
                overflow-y: auto;
                overflow-x: hidden;
                margin-top: 20px;
                padding-bottom: 10px;
               .sidebar-collapse-content-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 7px auto;
                    padding: 0;
                    width: 36px;
                    height: 36px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                .selected{
                    background: #f0f3f5;
                    .icon{
                        color: @primary-color;
                    }
                }
            }


            .sidebar-collapse-footer{
                .sidebar-collapse-content-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    width: 36px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                .sidebar-collapse-footer-view {
                    margin: 0 auto;
                    padding-top: 0;
                    height: 52px;
                    background: #f9fafb;
                    text-align: center;
                }
            }
        }

        .split-line-linear-gray {
            display: block;
            height: 1px !important;
            background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #f0f3f5, hsla(0, 0%, 100%, 0));
        }

        .view-container {
            display: inline-flex;
            vertical-align: middle;
            mix-blend-mode: normal;
            cursor: pointer;

            .btn-column {
                width: 24px;
                height: 24px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
            }
        }

        .list-item:hover{
            color: #ff5603;
        }
    }
}

</style>
<style>
a {
    color: #000;
}

input,
textarea {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
}

.qrcode img{
    width: 100%;
}

.ant-list-item-meta-avatar {
    margin-right: 10px;
}

.ant-dropdown-menu-submenu-title{
    padding: 12px;
}

.ant-modal-body{
    border-radius: 10px;
}

.ant-modal-header{
    border-radius: 10px 10px 0 0;
}

.ant-modal-content{
    border-radius: 10px;
}

.ant-dropdown-menu{
    border-radius: 5px; 
}

.ant-card{
    border-radius: 10px;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #fff2e6;
    border-color: rgba(0, 0, 0, 0.03);
}

.ant-badge-count{
    min-width: 18px;
    height: 18px;
    line-height: 18px;
}

::-webkit-scrollbar {
    height: 15px;
    width: 15px;
    background: transparent;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    width: 15px;
    min-height: 60px;
    margin-right: 1px;
    background-color: #e1e6ed;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-track-piece {
    background: transparent;
}

.loading{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 80;    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

</style>