import request from '@/utils/request'

const systemApi = {
    GetSystemInfo: '/userAdminApi/system/getSystemInfo',
}

//获取系统信息
export function getSystemInfo(parameter){
    return request({
        url: systemApi.GetSystemInfo,
        method: 'get',
        params: parameter
    })
}
