import request from '@/utils/request'

const qrcodeApi = {
    GetQcodeStatus:'/userAdminApi/qrcode/getQcodeStatus',
}

//获取二维维状态
export function getQcodeStatus(parameter){
    return request({
        url: qrcodeApi.GetQcodeStatus,
        method: 'get',
        params: parameter
    })
}