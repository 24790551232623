<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="450"
        :maskClosable="false"
        title="修改密码"
        :confirm-loading="confirmLoading"
        @ok="clickEdit"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-form ref="editFormRef" :model="editFormState" :labelCol="{span:5}">
            <a-form-item label="新密码" name="newPassword" :rules="[{ required: true, message: '请输入新密码' }]">
                <a-input-password v-model:value="editFormState.newPassword" placeholder="请输入新密码" allowClear/>
            </a-form-item>
            <a-form-item label="确认密码" name="repassword" :rules="[{ required: true, message: '请输入确认密码' }]">
                <a-input-password v-model:value="editFormState.repassword" placeholder="请输入确认密码" allowClear/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import {defineComponent,ref} from 'vue';
    import useUser from '@/stores/user';
    import { message } from 'ant-design-vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }){
            const userStore = useUser();
            
            const confirmLoading = ref(false);

            //编辑
            const editFormRef = ref();
            const editFormState = ref({
                newPassword:'',
                repassword:''
            });

            const clickEdit = () => {
                editFormRef.value.validateFields().then(values => {
                    if(values['newPassword'] != values['repassword']){
                        message.error('输入新密码不一致');
                    }else{
                        confirmLoading.value = true;

                        userStore.ChangePassword(values).then((res) => {
                            if (res.status == 1) {
                                emit('cancel');
                                message.success(res.message);
                            }else{
                                message.error(res.message);
                            }

                            confirmLoading.value = false;
                        })
                    }
                }).catch(info => {
                
                });
            }

            return {
                confirmLoading,

                editFormRef,
                editFormState,
                clickEdit,
            }
        }
    })
</script>