<template>
    <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
        <a-typography-title :level="5" style="margin-bottom: 20px;">邀请企业</a-typography-title>
        <a-tabs v-model:activeKey="activeKey" @change="tabChange">
            <a-tab-pane key="1" tab="工具邀请海报">
                <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 4px;">
                    <div id="poster" class="poster">
                        <div class="poster_user">
                            <div class="poster_user_avatar">
                                <img :src="userInfo['avatar'] ? $common.cdnurl(userInfo['avatar'],true) : ''"/>
                            </div>
                            <div class="poster_user_info">
                                <div class="user_name">{{ userInfo['name'] }}</div>
                                <div class="user_intro">推荐您使用</div>
                            </div>
                        </div>
                        <div class="poster_content">
                            <img class="poster_logo" src="../../assets/logo.png" />
                            <div class="poster_name">猫嗅笔记</div>
                            <div class="poster_intro">一款好用的企业推品工具</div>
                            <div class="poster_slogan">
                                <div class="tag" style="width: 160px;">快速搭建企业共享产品库</div>
                                <div class="tag" style="width: 160px;">一秒制作有图推品报价表</div>
                                <div class="tag" style="width: 160px;">一键下载产品上架素材包</div>
                                <div class="tag" style="width: 160px;">一键合并多笔记推品给客户</div>
                            </div>
                            <div id="qrcode" class="qrcode"></div>
                            <div class="poster_scan">长按识别二维码 免费创建企业产品库</div>
                        </div>
                    </div>
                    <div style="text-align: center;">
                        <a-button @click="clickSaveQrcodePoster('poster')" type="primary" style="margin-top: 30px;">保存到电脑</a-button>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="产品库邀请海报">
                <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 4px;">
                    <div id="poster1" class="poster1">
                        <div class="poster_user">
                            <div class="poster_user_avatar">
                                <img :src="userInfo['avatar'] ? $common.cdnurl(userInfo['avatar'],true) : ''"/>
                            </div>
                            <div class="poster_user_info">
                                <div class="user_name">{{ userInfo['name'] }}</div>
                                <div class="user_intro">邀请您加入“共享货源库”</div>
                            </div>
                        </div>
                        <div class="poster_content">
                            <img class="poster_logo" src="../../assets/logo.png" />
                            <div class="poster_name">猫嗅笔记</div>
                            <div class="poster_intro">一个渠道互采的共享平台</div>
                            <div style="margin-top: 15px;">
                                <div style="margin-bottom: 6px;">
                                    <div style="font-size: 14px;font-weight: bold;">企业产品库</div>  
                                    <div class="tag" style="width: 40px;margin-top: 2px;">私库</div>
                                </div>
                                <div style="display: flex;align-items: center;justify-content: space-between;">
                                    <div>
                                        <div style="font-size: 14px;font-weight: bold;">录入产品</div>
                                        <div class="tag" style="width: 40px;margin-top: 2px;">共享</div>
                                    </div>

                                    <div style="position: relative;;width: 220px;height: 220px;display: flex;justify-content: center;align-items: center;">
                                        <img style="position: absolute;width: 320px;height: 320px;" src="../../assets/images/liucheng.png"/>
                                       <div>
                                            <div id="qrcode1" class="qrcode" style="margin-top: 0;width: 100px;height: 100px;"></div>
                                            <div class="poster_scan">长按识别二维码 </div>
                                            <div class="poster_scan" style="margin-top: 0;">免费创建“企业产品库”</div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style="font-size: 14px;font-weight: bold;">挑选货源</div>
                                        <div class="tag" style="width: 40px;margin-top: 2px;">上架</div>
                                    </div>
                                </div>
                                <div style="margin-top: 6px;">
                                    <div style="font-size: 14px;font-weight: bold;">共享货源库</div>  
                                    <div class="tag" style="width: 40px;margin-top: 2px;">公库</div>
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div style="text-align: center;">
                        <a-button @click="clickSaveQrcodePoster('poster1')" type="primary" style="margin-top: 30px;">保存到电脑</a-button>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="邀请注册链接">
                <div style="text-align: center;">
                    <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 4px;margin-bottom: 20px;">
                        <div style="margin-bottom: 15px;">推荐您使用猫嗅笔记，可通过此链接https://note.maoxiubiji.com/#/user/register?user_code={{ userInfo['user_code'] }}</div>

                        <a-button type="primary" @click="clickCopy(`推荐您使用猫嗅笔记，可通过此链接https://note.maoxiubiji.com/#/user/register?user_code=${ userInfo['user_code'] }`);">复制链接</a-button>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>
    </a-card>
</template>
<script>
    import { defineComponent,ref,computed,onMounted,nextTick} from 'vue';
    import useUser from '@/stores/user';

    import common from '@/utils/common';

    import QRCode from "qrcodejs2"
    import html2canvas from 'html2canvas'
    import FileSaver from 'file-saver';

    export default defineComponent({
        setup() {
            const userStore = useUser();
            const userInfo =  computed(() => userStore.userInfo);

            //生成二维码
            const clickCreatQrcode = (id) => {
                document.getElementById(id).innerHTML = "";
                new QRCode(document.getElementById(id), {
                    text: 'https://note.maoxiubiji.com/#/user/register?user_code='+userInfo.value.user_code, // 需要转换为二维码的内容
                    width: 800,
                    height:800,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.L
                })
            }

            onMounted(()=>{
                nextTick(() => {
                    // 生成二维码
                    clickCreatQrcode('qrcode');
                })
            })

            const tabChange = (activeKey) => {
                if(activeKey == 1){
                    nextTick(() => {
                        // 生成二维码
                        clickCreatQrcode('qrcode');
                    })
                }else if(activeKey == 2){
                    nextTick(() => {
                        // 生成二维码
                        clickCreatQrcode('qrcode1');
                    })
                }
            }

            //保存二维码海报
            const clickSaveQrcodePoster = (id) => {
                html2canvas(document.getElementById(id), {
                    scale: 6,
                    useCORS: true, // 解决跨域保存图片的问题
                }).then(canvas => {
                    // 将canvas内容保存为文件并下载
                    canvas.toBlob((blob) => {
                        FileSaver(blob,'二维码海报.png')
                    })
                })
            }

             //复制
            const clickCopy = async (text) => {
                common.copy(text);
            }
            return {
                userInfo,

                activeKey: ref('1'),
                tabChange,

                clickSaveQrcodePoster,
                clickCopy,
            }
        }
    })
</script>
<style lang="less" scoped>
    .poster{
        width: 255px;
        background-image: linear-gradient(180deg,rgba(240,244,255,.5) 0,#fff);
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 16px rgba(28,76,186,.08);
        margin: 0 auto;
    }

    .poster1{
        width: 370px;
        background-image: linear-gradient(180deg,rgba(240,244,255,.5) 0,#fff);
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0 2px 16px rgba(28,76,186,.08);
        margin: 0 auto;
    }

    .poster_user{
        display: flex;
        align-items: center;
        .poster_user_avatar{
            width: 35px;
            height: 35px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 35px;
            }
        }

        .poster_user_info{
            margin-left: 6px;
            .user_name{
                font-size: 13px;
                font-weight: 500;
            }
            .user_intro{
                font-size: 10px;
                color: #51565d;
            }
        }
    }

    .poster_content{
        margin-top: 20px;
        text-align: center;
        .poster_logo{
            width: 50px;
        }
        .poster_name{
            margin-top: 10px;
            color: #1f2329;
            font-weight: 700;
            font-size: 16px;
        }

        .poster_intro{
            margin-top: 5px;
            font-size: 12px;
            color: #51565d;
        }

        .poster_slogan{
            margin-top: 15px;
        }

        .tag{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            margin-top: 10px;
            color: #d46b08;
            background: #fff7e6;
            border: 1px solid #ffd591;
            border-radius: 2px;
            font-size: 12px;
        }

        .qrcode{
            border: 0;
            outline: 0;
            display: block;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            width: 90px;
            height: 90px;
            background-color: #fff;
            padding: 5px;
            border: 1px solid #eee;
            border-radius: 5px;
            margin-top: 25px;
        }

        .poster_scan{
            margin-top: 10px;
            font-size: 13px;
            color: #8f959e;
        }
    }
</style>