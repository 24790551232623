import FileSaver from 'file-saver';
import { message } from 'ant-design-vue';

function openUrl(url) {
    if (isElectron()) {
        const { shell } = window.require('electron');
        shell.openExternal(url);
    } else {
        window.open(url, '_blank');
    }
}

function cdnurl(url, domain) {
    var rule = new RegExp('^((?:[a-z]+:)?\\/\\/|data:image\\/)', 'i')
    var url = rule.test(url) ? url : 'https://oss.maoxiubiji.com/' + url
    if (domain && !rule.test(url)) {
        domain = typeof domain === 'string' ? domain : location.origin
        url = domain + url
    }
    return url
}

function isElectron() {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('electron/') > -1) {
        return true;
    } else {
        return false;
    }
}

function isMac() {
    if (isElectron()) {
        return process.platform == 'darwin' ? true : false;
    } else {
        return false;
    }
}

function isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag
}

function showMainWindow() {
    if (isElectron()) {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('showMainWindow')
    }
}

function showLoginWindow() {
    if (isElectron()) {
        const { ipcRenderer } = window.require('electron');
        ipcRenderer.send('showLoginWindow')
    }
}

function copy(content) {
    if (isElectron()) {
        const { clipboard } = window.require('electron');
        try {
            // 复制
            clipboard.writeText(content);
            // 复制成功
            message.success('复制成功');
        } catch (e) {
            // 复制失败
            message.error('复制失败');
        }
    } else {
        try {
            const dataContainer = document.createElement('input');
            dataContainer.value = content;
            document.body.appendChild(dataContainer);
            // 选中并复制文本到剪切板
            dataContainer.select();
            document.execCommand('copy');
            // 移除input元素
            document.body.removeChild(dataContainer);
            message.success('复制成功');
        } catch (e) {
            // 复制失败
            message.error('复制失败');
        }
    }
}

function compare(curV, reqV) {
    if (curV && reqV) {
        //将两个版本号拆成数字
        var arr1 = curV.split('.'),
            arr2 = reqV.split('.');
        var minLength = Math.min(arr1.length, arr2.length),
            position = 0,
            diff = 0;
        //依次比较版本号每一位大小，当对比得出结果后跳出循环（后文有简单介绍）
        while (position < minLength && ((diff = parseInt(arr1[position]) - parseInt(arr2[position])) == 0)) {
            position++;
        }
        diff = (diff != 0) ? diff : (arr1.length - arr2.length);
        //若curV大于reqV，则返回true
        return diff > 0;
    } else {
        return false;
    }
}

function sizeTostr(size) {
    var data = "";
    if (size < 0.1 * 1024) { //如果小于0.1KB转化成B  
        data = size.toFixed(2) + "B";
    } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB  
        data = (size / 1024).toFixed(2) + "KB";
    } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB  
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
    } else { //其他转化成GB  
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }
    var sizestr = data + "";
    var len = sizestr.indexOf("\.");
    var dec = sizestr.substr(len + 1, 2);
    if (dec == "00") {//当小数点后为00时 去掉小数部分  
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
}

function formatSeconds(value) {
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    var hourTime = 0;// 小时
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取余，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取余的分，获取分钟除以60取余的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var result = "" + parseInt(secondTime) + "秒";

    if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
    }
    if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
    }
    return result;
}

function saveFile (blob,title,type){
    if (isElectron()) {
        const { dialog } = require('@electron/remote');
        const fs = require('fs-extra');
        dialog.showSaveDialog({
            defaultPath: `${title}.${type}`,
            buttonLabel: "保存",
            showsTagField:false,
            filters: [
                {name: type, extensions: [type]},
            ]
        }).then(res => {
            readBlobAsBase64(blob, function (data){
                const base64Data = type == 'zip' ? data.replace(/^data:application\/zip;base64,/, "") : data.replace(/^data:application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;base64,/, "");
                const buffer = Buffer.from(base64Data, 'base64');

                fs.writeFileSync(res.filePath,buffer);
                message.success('文件已保存');
            })
        }).catch(err => {
            console.log("err", err)
        })
    }else{
        FileSaver.saveAs(blob, `${title}.${type}`);
    }
}

// 把blob对象转换为Base64
function readBlobAsBase64(blob, callback) {
    var a = new FileReader();
    a.onload = function(e) {callback(e.target.result);};
    a.readAsDataURL(blob);
}

export default {
    openUrl,
    cdnurl,
    isElectron,
    isMac,
    isMobile,
    showMainWindow,
    showLoginWindow,
    copy,
    compare,
    sizeTostr,
    formatSeconds,
    saveFile,
    readBlobAsBase64,
}