import request from '@/utils/request'

const shareholderApi = {
    GetShareholderOrderList:'/userAdminApi/shareholder/getShareholderOrderList',
    GetShareholderUserBalance:'/userAdminApi/shareholder/getShareholderUserBalance',
    GetShareholderUserBalanceLog:'/userAdminApi/shareholder/getShareholderUserBalanceLog',
    GetShareholderUserList:'/userAdminApi/shareholder/getShareholderUserList',
    GetActivationCodeList:'/userAdminApi/shareholder/getActivationCodeList',
}

//获取股东我的团队订单列表
export function getShareholderOrderList(parameter){
    return request({
        url: shareholderApi.GetShareholderOrderList,
        method: 'get',
        params: parameter
    })
}

//获取股东用户余额
export function getShareholderUserBalance(parameter){
    return request({
        url: shareholderApi.GetShareholderUserBalance,
        method: 'get',
        params: parameter
    })
}

//获取股东用户余额记录
export function getShareholderUserBalanceLog(parameter){
    return request({
        url: shareholderApi.GetShareholderUserBalanceLog,
        method: 'get',
        params: parameter
    })
}

//获取我的股东用户列表
export function getShareholderUserList(parameter){
    return request({
        url: shareholderApi.GetShareholderUserList,
        method: 'get',
        params: parameter
    })
}

//获取激活码列表
export function getActivationCodeList(parameter){
    return request({
        url: shareholderApi.GetActivationCodeList,
        method: 'get',
        params: parameter
    })
}

