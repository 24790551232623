
import UserLayout from '@/layouts/UserLayout';
import BasicLayout from '@/layouts/BasicLayout';
import RouteView from '@/layouts/RouteView';

//权限路由
export const asyncRouterMap = [
    {
        path: '/',
        redirect: '/note',
        component: BasicLayout,
        meta:{
            title:'首页'
        },
        children: [
            {
                path: '/note',
                redirect: '/note/index',
                component: RouteView,
                meta:{
                    title:'产品列表'
                },
                children: [
                    {
                        path: '/note/index',
                        component: () => import('@/pages/note/Index')
                    },
                ]
            },
            {
                path: '/category',
                redirect: '/category/index',
                component: RouteView,
                meta:{
                    title:'分类列表'
                },
                children: [
                    {
                        path: '/category/index',
                        component: () => import('@/pages/category/Index')
                    },
                ]
            },
            {
                path: '/brand',
                redirect: '/brand/index',
                component: RouteView,
                meta:{
                    title:'品牌列表'
                },
                children: [
                    {
                        path: '/brand/index',
                        component: () => import('@/pages/brand/Index')
                    },
                ]
            },
            {
                path: '/supplier',
                redirect: '/supplier/index',
                component: RouteView,
                meta:{
                    title:'供应商列表'
                },
                children: [
                    {
                        path: '/supplier/index',
                        component: () => import('@/pages/supplier/Index')
                    },
                ]
            },
            {
                path: '/note_folder',
                redirect: '/note_folder/index',
                component: RouteView,
                meta:{
                    title:'我的笔记夹'
                },
                children: [
                    {
                        path: '/note_folder/index',
                        component: () => import('@/pages/note_folder/Index')
                    }
                ]
            },
            {
                path: '/share_note',
                redirect: '/share_note/index',
                component: RouteView,
                meta:{
                    title:'共享列表'
                },
                children: [
                    {
                        path: '/share_note/index',
                        redirect:'/share_note/receiver',
                        component: () => import('@/pages/share_note/Index'),
                        children: [
                            {
                                path: '/share_note/receiver',
                                component: () => import('@/pages/share_note/Receiver')
                            },
                            {
                                path: '/share_note/sender',
                                component: () => import('@/pages/share_note/Sender')
                            }
                        ]
                    }
                ]
            },
            {
                path: '/share_group',
                redirect: '/share_group/index',
                component: RouteView,
                meta:{
                    title:'分组共享'
                },
                children: [
                    {
                        path: '/share_group/index',
                        component: () => import('@/pages/share_group/Index')
                    }
                ]
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/404',
    }
];

//基础路由
export const constantRouterMap = [
    {
        path: '/user', 
        redirect: '/user/login',
        component: UserLayout,
        meta:{
            title:'用户'
        },
        children: [
            {
                path: 'login',
                name:'login',
                component: () => import('@/pages/user/Login')
            },
            {
                path: 'register',
                name:'register',
                component: () => import('@/pages/user/Register')
            },
            {
                path: 'invite',
                name:'invite',
                component: () => import('@/pages/user/Invite')
            },
        ]
    },
    {
        path: '/storageShare',
        name:'storageShare',
        component: () => import( '@/pages/StorageShare')
    },
    {
        path: '/404',
        component: () => import( '@/pages/404')
    }
];