<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :maskClosable="false"
        title="个人资料"
        :confirm-loading="confirmLoading"
        @ok="clickEdit"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-form ref="editFormRef" :model="editFormState" :labelCol="{span:3}">
            <a-form-item label="头像" name="avatar" :rules="[{ required: true, message: '上传头像' }]">
                <uploadPicture  v-model:value="editFormState.avatar" uploadText="上传头像"/>
            </a-form-item>
            <a-form-item label="姓名" name="name" :rules="[{ required: true, message: '请输入姓名' }]">
                <a-input v-model:value="editFormState.name"  placeholder="请输入姓名" allowClear/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import {defineComponent,ref,computed} from 'vue';
    import uploadPicture from '@/components/uploadPicture';
    import useUser from '@/stores/user';
    import { message } from 'ant-design-vue';
    export default defineComponent({
        components: {
            uploadPicture,
        },
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }) {
            const userStore = useUser();
            const userInfo =  computed(() => userStore.userInfo);

            const confirmLoading = ref(false);

            //编辑
            const editFormRef = ref();
            const editFormState = ref({
                avatar:userInfo.value.avatar,
                name:userInfo.value.name,
            });

            const clickEdit = () => {
                editFormRef.value.validateFields().then(values => {
                    confirmLoading.value = true;
                    userStore.UpdateUserInfo(values).then((res) => {
                        if (res.status == 1) {
                            emit('cancel');
                            message.success(res.message);
                        }else{
                            message.error(res.message);
                        }

                        confirmLoading.value = false;
                    })
                }).catch(info => {
                
                });
            }

            return {
                confirmLoading,

                editFormRef,
                editFormState,
                clickEdit,
            }
        }
    })
</script>