<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="450"
        :maskClosable="false"
        title="修改手机号"
        :confirm-loading="confirmLoading"
        @ok="clickEdit"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-form ref="editFormRef" :model="editFormState" :labelCol="{span:4}">
            <a-form-item label="手机号" name="mobile" :rules="[{ required: true, message: '请输入新手机号' }]">
                <a-input v-model:value="editFormState.mobile" placeholder="请输入新手机号" allowClear/>
            </a-form-item>
            <a-form-item label="验证码" name="code" :rules="[{ required: true, message: '请输入验证码' }]">
                <a-input v-model:value="editFormState.code" placeholder="请输入验证码" allowClear>
                    <template #suffix>
                        <span v-if="!showTimer" @click="clickGraphicalCheck" class="code">获取验证码</span>
                        <span v-else class="code" style="color:#b2b2b2">重新发送({{ codeTimer }}s)</span>
                    </template>
                </a-input>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import {defineComponent,ref} from 'vue';
    import useUser from '@/stores/user';
    import { message } from 'ant-design-vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }){
            const userStore = useUser();

            const confirmLoading = ref(false);

            //编辑
            const editFormRef = ref();
            const editFormState = ref({
                mobile:'',
                code:''
            });

            const clickEdit = () => {
                editFormRef.value.validateFields().then(values => {
                    confirmLoading.value = true;
                    userStore.SmsCheck({
                        mobile:values.mobile,
                        code : values.code,
                        event:'changemobile',
                    }).then((res) => {
                        if (res.status == 1) {
                            userStore.ChangeMobile(values).then((res) => {
                                if (res.status == 1) {
                                    emit('cancel');
                                    message.success(res.message);
                                }else{
                                    message.error(res.message);
                                }

                                confirmLoading.value = false;
                            })
                        }else{
                            message.error(res.message);

                            confirmLoading.value = false; 
                        }
                    })
                })
            }

             //图片验证码
             const clickGraphicalCheck = () => {
                editFormRef.value.validateFields(['mobile']).then(values => {
                    try {
						let captcha = new TencentCaptcha('197751719', clickCallback, {
							userLanguage: 'zh-cn',
							needFeedBack:false,
						});
						captcha.show();
					}catch (error) {
						console.log(error);
					}
                }).catch(info => {
                
                });
            }

            //图片验证码回调
			const clickCallback = (res) => {
				if (res.ret === 0) {
					clickSendSmsCode('changemobile',res.ticket,res.randstr);
				}
			}

            //发送短信验证码
            const codeTimer = ref(60); 
            const showTimer = ref(false);
            const clickSendSmsCode = (event,ticket,randstr) => {
                editFormRef.value.validateFields(['mobile']).then(values => {
                    showTimer.value = true;

                    const times1 = setInterval(()=>{
                        if(codeTimer.value === 0){
                            clearInterval(times1);
                            showTimer.value = false;
                            codeTimer.value = 60;
                        }else{
                            codeTimer.value--;
                        }
                    },1000)

                    userStore.SmsSend({
                        mobile:values.mobile,
                        event:event,
                        ticket:ticket,
                        randstr:randstr,
                    }).then((res) => {
                        if (res.status == 1) {
                            message.success(res.message);
                        }else {

                            clearInterval(times1);
                            showTimer.value = false;
                            codeTimer.value = 60;
                            
                            message.error(res.message);
                        }
                    })
                }).catch(info => {
                
                });
            }

            return {
                confirmLoading,
                

                editFormRef,
                editFormState,
                clickEdit,

                clickGraphicalCheck,
                clickCallback,

                codeTimer,
                showTimer,
                clickSendSmsCode,
            }
        }
    })
</script>
<style lang="less" scoped>
    .code {
        font-size: 14px;
        color: @primary-color;
        cursor: pointer;
    }
</style>