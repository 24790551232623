<template>
    <div>
        <div class="float-button" @click="clickFeedbackModal">
            <a-tooltip placement="right">
                <div class="fixed-widgets">
                    <div class="ant-avatar fixed-widgets-avatar">
                        <mail-outlined :style="{ fontSize: '16px' }" />
                    </div>
                </div>
                <template #title>问题反馈</template>
            </a-tooltip>
        </div>

        <!-- 问题反馈 -->
        <a-modal v-if="!isSuccess" v-model:visible="feedbackVisible" :width="600" :centered="true" :maskClosable="false" :confirm-loading="confirmLoading" title="问题反馈" @ok="clickAdd" style="-webkit-app-region: no-drag">
            <a-form ref="feedbackFormRef" :model="feedbackFormState" :labelCol="{ span: 4 }">
                <a-form-item label="反馈类型" name="feedback_type">
                    <a-radio-group v-model:value="feedbackFormState.feedback_type">
                        <a-radio value="10">困惑与建议</a-radio>
                        <a-radio value="20">Bug 缺陷</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="终端类型" name="terminal_type">
                    <a-radio-group v-model:value="feedbackFormState.terminal_type">
                        <a-radio value="10">桌面端</a-radio>
                        <a-radio value="20">移动端</a-radio>
                        <a-radio value="30">Web端</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="反馈标题" name="title" :rules="[{ required: true, message: '请输入反馈标题' }]">
                    <a-input placeholder="请输入反馈标题" v-model:value="feedbackFormState.title" allowClear/>
                </a-form-item>
                <a-form-item label="反馈描述" name="describe" :rules="[{ required: true, message: '请输入反馈描述' }]">
                    <a-textarea placeholder="请输入反馈描述" :rows="5" v-model:value="feedbackFormState.describe"  />
                </a-form-item>
                <a-form-item label="反馈截图" name="images">
                    <a-input v-model:value="feedbackFormState.images" style="display:none"/>
                    <a-upload
                        accept="image/*"
                        action="https://upload-z0.qiniup.com"
                        v-model:file-list = fileList
                        :multiple = true
                        :data = dataState
                        :before-upload="beforeUpload"
                        @change="handleChange"
                        @remove="handleRemove"
                    >
                        <a-button>
                            <upload-outlined></upload-outlined>上传截图
                        </a-button>
                    </a-upload>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-else v-model:visible="feedbackVisible" :centered="true" :maskClosable="false" title="反馈成功"  :footer="null" style="-webkit-app-region: no-drag">
            <div style="text-align: center;">
                <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 10px;">
                    <img style="width: 200px;" src="../assets/images/qrcode.jpg" />
                    <div style="margin-top: 15px;margin-bottom: 15px;font-size: 14px;color: #666;">绑定微信公众号，我们将通过公众号发送反馈通知</div>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import { defineComponent,ref,reactive} from 'vue';
import { UploadOutlined, MailOutlined } from '@ant-design/icons-vue';
import {addFeedback} from '@/api/feedback';
import {getQiniuUploadToken} from '@/api/upload';
import { message } from 'ant-design-vue';
export default defineComponent({
    components: {
        UploadOutlined,
        MailOutlined,
    },

    setup() {
        const confirmLoading = ref(false);

        const isSuccess = ref(false);

        //问题反馈
        const feedbackFormRef = ref();
        const feedbackVisible = ref(false);
        const feedbackFormState = reactive({
            feedback_type: '10',
            terminal_type: '10',
            images:[],
        });
        const clickFeedbackModal = () => {
            isSuccess.value = false;
            feedbackVisible.value = true;

            fileList.value = [];
            feedbackFormRef.value.resetFields();
        }

        const clickAdd = () => {
            feedbackFormRef.value.validateFields().then(values => {
                confirmLoading.value = true;
                addFeedback(values).then((res) => {
                    if (res.status == 1) {
                        fileList.value = [];
                        feedbackFormRef.value.resetFields();
                        isSuccess.value = true;
                    } else {
                        message.error(res.message);
                    }

                    confirmLoading.value = false;
                })
            }).catch(info => {
                
            });
        }

        const fileList = ref([]);

        const dataState = ref({
            token:'',
            name:'',
        });

        const beforeUpload = async (file) => {
            const res = await getQiniuUploadToken();
            dataState.value = {
                token:res.result,
                name :file.name
            }
        }

        const handleChange = info => {
            if (info.file.status === 'done') {
                if(info['fileList'].length <=0){
                    return false;
                }

                let images = [];
                info['fileList'].forEach((item)=>{
                    if(item.response){
                        images.push(item.response.key);
                    }
                })

                feedbackFormState.images = images;
            }
        }   

        const handleRemove = info => {
            feedbackFormState['images'].forEach((item,index)=>{
                if(item === info.response.key){
                    feedbackFormState['images'].splice(index, 1);
                }
            })
        }

        return {
            confirmLoading,

            isSuccess,

            feedbackFormRef,
            feedbackVisible,
            feedbackFormState,

            clickFeedbackModal,
            clickAdd,

            fileList,
            dataState,
            beforeUpload,
            handleChange,
            handleRemove
        }
    }
})
</script>
<style lang="less" scoped>
.float-button {
    position: fixed;
    right: 22px;
    bottom: 50px;
    z-index: 100;

    .fixed-widgets {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .fixed-widgets-avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            font-size: 22px;
            color: #000;
            background-color: #fff;
            box-shadow: 0 4px 8px -4px rgba(0, 0, 0, .13), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 12px 24px 16px rgba(0, 0, 0, .04);
            transition: transform 167ms cubic-bezier(.33, 0, 0, 1) 0s;
        }

        .fixed-widgets-avatar:hover {
            transition: transform .25s cubic-bezier(.33, 0, 0, 1) 0s;
            transform: scale(1.1);
        }
    }
}
</style>