import request from '@/utils/request'

const feedbackApi = {
    AddFeedback: '/userAdminApi/feedback/addFeedback',
}

//添加反馈
export function addFeedback(parameter){
    return request({
        url: feedbackApi.AddFeedback,
        method: 'post',
        data: parameter
    })
}


