<template>
    <div>
        <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
            <a-typography-title :level="5" style="margin-bottom: 20px;">我的团队</a-typography-title>
            <div style="margin-top: 16px;-webkit-app-region: no-drag">
                <a-table 
                    ref="table"
                    size="middle" 
                    :row-key = "record => record.id"
                    :columns = "columns"
                    :data-source = "dataSource" 
                    :pagination = "pagination"
                    :loading = "loading"
                    @change = "handleTableChange"
                    :scroll = "{ x: 'max-content'}"
                >
                </a-table>
            </div>
        </a-card>
    </div>
</template>
<script>
    import { defineComponent,computed} from 'vue';
    import { usePagination } from 'vue-request';
    import {getDistributionUserList} from '@/api/distribution'
    export default defineComponent({
        setup() {
            //table
            const columns = [
                {
                    title: '姓名',
                    dataIndex: 'name',
                    align: 'left',
                },
                {
                    title: '手机号',
                    dataIndex: 'mobile',
                    align: 'left',
                },
                {
                    title: '注册时间',
                    dataIndex: 'createtime',
                    align: 'left',
                },
                {
                    title: '直邀会员数',
                    dataIndex: 'directUserListCount',
                    align: 'left',
                },
                {
                    title: '直推订单数',
                    dataIndex: 'directOrderListCount',
                    align: 'left',
                },
                {
                    title: '直推成交额',
                    dataIndex: 'directOrderListSum',
                    align: 'left',
                },
            ];

            const queryData = params => {
                return getDistributionUserList(params).then((res) => {
                    return res.result;
                })
            };

            const {
                data,
                run,
                loading,
                current,
                pageSize,
                refresh
            } = usePagination(queryData);

            const dataSource = computed(() => data.value ? data.value.data : []);

            const pagination = computed(() => (
                {
                    total: data.value ? data.value.totalCount : '',
                    current: current.value,
                    pageSize: pageSize.value,
                    showTotal:(total) => `共 ${total} 条`,
                    pageSizeOptions:['10', '20', '30', '40', '50', '100'],
                    showSizeChanger:true,
                }
            ));

            const handleTableChange = (pag, filters, sorter) => {
                run({
                    current:pag.current,
                    pageSize:pag.pageSize,
                })
            }

            return {
                queryData,
                columns,
                dataSource,

                pagination,
                loading,
                handleTableChange,
            }
        }
    })
</script>