<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="600"
        :maskClosable="false"
        title="企业切换"
        :body-style="{ padding: '15px 24px' }"
        :footer="null"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <div class="table-tool" style="margin-bottom: 8px">
            <div class="table-operator">
                <a-button @click="addOrganizationModal"  type="primary">创建新企业产品库</a-button>
            </div>
        </div>
        <a-table 
            ref="table"
            size="middle" 
            :columns = "columns.filter((col, num) => {
                if (col.show) {
                    return col
                }
            })"
            :data-source = "dataSource" 
            :pagination="false"
            :loading = "loading"
        >   
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'organization_name'">
                    <div v-if="record['is_founder']"  @click="editOrganizationModal(record)">
                        <span>{{ text }}</span>
                        <edit-outlined style="margin-left: 8px;"/>
                    </div>
                    <span v-else>{{ text }}</span>
                </template>
                <template v-if="column.dataIndex === 'operation'">
                    <span v-if="record['is_current_organization'] == true">
                        <a-tag style="margin-right:0" color="green">当前企业</a-tag>
                    </span>
                    <span v-else>
                        <a-button v-if="record.status === 'normal'" @click="clickOrganizationLogin({organization_id:record['organization_id'],user_id:record['user_id']});" type="primary" size="small">进入企业</a-button>
                        <span v-else>
                            <a-tag style="margin-right:0" color="orange">你在该企业账号被禁用</a-tag>
                        </span>
                    </span>
                </template>
            </template>
        </a-table>
    </a-modal>

    <!-- 添加企业 -->
    <add-organization-modal v-if="addOrganizationModalVisible" :visible="addOrganizationModalVisible" @cancel="addOrganizationModalVisible = false" @refresh="clickReset"></add-organization-modal>

    <!-- 编辑企业 -->
    <edit-organization-modal v-if="editOrganizationModalVisible" :visible="editOrganizationModalVisible" :data="editOrganizationData" @cancel="editOrganizationModalVisible = false" @refresh="clickReset"></edit-organization-modal>
</template>
<script>
    import {defineComponent,ref,computed} from 'vue';

    import {EditOutlined} from '@ant-design/icons-vue';

    import { useRequest } from 'vue-request';
    import { getOrganizationList} from '@/api/organization';

    import addOrganizationModal from '@/components/organization/addOrganizationModal';
    import editOrganizationModal from '@/components/organization/editOrganizationModal';

    import { useRouter } from 'vue-router';
    import useUser from '@/stores/user';

    import { message } from 'ant-design-vue';

    export default defineComponent({
        components: {
            EditOutlined,
            
            addOrganizationModal,
            editOrganizationModal,
        },
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(){
            const router = useRouter();
            const userStore = useUser();

            const userInfo = computed(() => userStore.userInfo);

            const clickReset = () => {
                run()
            }

            //table
            const columns = [
                {
                    title: '企业名称',
                    dataIndex: 'organization_name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '创建人',
                    dataIndex: 'name',
                    align: 'left',
                    show: true,
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    align: 'left',
                    fixed: 'right',
                    show: true,
                },
            ];

            const queryData = params => {
                return getOrganizationList(params).then((res) => {
                    return res.result;
                })
            };

            const {
                data,
                run,
                loading,
                refresh,
            } = useRequest(queryData)

            const dataSource = computed(() =>Object.assign( data.value ? data.value : []));

            //添加
            const addOrganizationModalVisible = ref(false);
            const addOrganizationModal = () => {
                addOrganizationModalVisible.value = true;
            }

            //编辑
            const editOrganizationModalVisible = ref(false);
            const editOrganizationData = ref();
            const editOrganizationModal = (record) => {
                editOrganizationModalVisible.value = true;
                editOrganizationData.value = record;
            }

            //企业登录
            const clickOrganizationLogin = values => {
                userStore.OrganizationLogin({
                    organization_id:values.organization_id,
                    user_id : values.user_id,
                }).then((res) => {
                    if (res.status == 1) {
                        router.push('/');
                        
                        setTimeout(function () {
                            location.reload();
                        }, 100);
                    }else{
                        message.error(res.message);
                    }
                }).catch((err) => {
                    
                })
            }

            return {
                userInfo,

                clickReset,

                queryData,
                columns,
                dataSource,

                loading,

                addOrganizationModalVisible,
                addOrganizationModal,

                editOrganizationModalVisible,
                editOrganizationData,
                editOrganizationModal,

                clickOrganizationLogin,
            }
        }
    })
</script>