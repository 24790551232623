<template>
    <div class="wrapper">
        <div class="loading" v-if="loading">
            <a-spin />
        </div>
        <div class="content-wrapper">
            <div v-if="systemInfo" class="content" v-html="systemInfo.distribution_instructions" />
        </div>
    </div>
</template>
<script>
    import { defineComponent,ref,onMounted} from 'vue';
    import { getSystemInfo} from '@/api/system'
    export default defineComponent({
        setup() {
            const loading = ref(false);

            const systemInfo = ref();
            
            onMounted(() => {
                loading.value = true;
                getSystemInfo().then((res) => {
                    loading.value = false;
                    systemInfo.value = res.result;
                });
            })

            return {
                loading,

                systemInfo,
            }
        }
    })
</script>
<style lang="less" scoped>
    .wrapper {
        min-width: auto;
        height: 550px;
    }

    .content-wrapper {
        position: relative;
        overflow: hidden;
    }

    .content{
        position: relative;
        display: block;
        padding: 15px;
        text-align: left;
    }
</style>
<style>
    .content img{
        width: 100%;
    }
</style>
