<template>
    <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
        <a-typography-title :level="5" style="margin-bottom: 20px;">我的订单</a-typography-title>
        <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="1" tab="直接订单">
                <direct-distribution-order v-if="activeKey ==1"></direct-distribution-order>
            </a-tab-pane>
            <a-tab-pane key="2" tab="间接订单">
                <indirect-distribution-order v-if="activeKey ==2"></indirect-distribution-order>
            </a-tab-pane>
        </a-tabs>
    </a-card>
</template>
<script>
    import { defineComponent,ref} from 'vue';
    import directDistributionOrder from '@/components/distributionAccount/directDistributionOrder';
    import indirectDistributionOrder from '@/components/distributionAccount/indirectDistributionOrder';
    export default defineComponent({

        components: {
            directDistributionOrder,
            indirectDistributionOrder,
        },

        setup() {
            return {
                activeKey: ref('1'),
            }
        }
    })
</script>