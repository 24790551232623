import router from './router';
import storage from 'store';

import pinia from '@/stores/store'
import useUser from '@/stores/user';
const userStore = useUser(pinia);

import useAsyncRouter from '@/stores/async-router';
const asyncRouterStore = useAsyncRouter(pinia);

//设置标题
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

//免登录
const allowList = ['login','register','invite','storageShare']

//登录路由
const loginRoutePath = '/user/login'
//默认路由
const defaultRoutePath = '/note/index'

router.beforeEach((to, from, next) => {
    // 设置标题
    setDocumentTitle(`${domTitle}`)
    // to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${domTitle} - ${to.meta.title}`)

    //storage.clearAll();

    if (to.path === '/user/register' || to.path === '/user/invite' || to.path === '/storageShare') {
        next()    
    }
 
    if (storage.get('token')) {
   
        if (to.path === loginRoutePath) {
            next({ path: defaultRoutePath });
        } else {
            if (asyncRouterStore.addRouters.length == 0) {
                userStore.GetUserAndOrganizationInfo().then((res) => {
                    asyncRouterStore.GenerateRoutes().then((res) => {

                        //动态创建路由
                        asyncRouterStore.addRouters.forEach(r => {
                            router.addRoute(r);
                        });

                        const redirect = decodeURIComponent(from.query.redirect || to.path)
                        if (to.path === redirect) {
                            // set the replace: true so the navigation will not leave a history record
                            next({ ...to, replace: true })
                        } else {
                            // 跳转到目的路由
                            next({ path: redirect })
                        }
                    })
                })
            } else {
                next()
            }
        }
    } else {
        if (allowList.includes(to.name)) {
            // 在免登录名单，直接进入
            next()
        } else {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } });
        }
    }
});

router.afterEach(() => {
    
})