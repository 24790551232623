import request from '@/utils/request'

const erpApi = {
    EditErp:'/userAdminApi/erp/editErp',
}

//编辑erp信息
export function editErp(parameter){
    return request({
        url: erpApi.EditErp,
        method: 'post',
        data: parameter
    })
}