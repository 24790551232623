<template>
    <a-modal
        v-if="!isDownload" 
        :visible="visible" 
        :closable ="data['enforce'] != 1 ? true : false"
        :centered="true" 
        :width="460"
        :maskClosable="false"
        title=""
        :body-style="{ padding: '0' }"
        :footer="null"
        style="-webkit-app-region: no-drag"
    >
        <div class="software-update">
            <div class="software-update-title">软件更新</div>
            <div class="software-update-msg">
                <img class="software-update-left" src="../assets/logo.png" />
                <div class="software-update-right">
                    <div style="font-weight: 500;font-size: 14px;line-height: 22px;color: #4e5a70;">猫嗅笔记最新{{data['newversion']}}版本来了！</div>
                    <div style="font-weight: normal;font-size: 12px;line-height: 20px;margin-top: 2px;color:#7a8599;">您目前使用版本为{{oldversion}}版本，新版本更新日志如下：</div>
                </div>
            </div>
            <div class="software-update-content">
                <div style="white-space: pre-wrap;" v-html="data['content']"></div>
            </div>
            <div style="display: flex;justify-content: space-between;padding-top: 20px;">
                <div></div>
                <div>
                    <a-button v-if="data['enforce'] != 1" @click="$emit('cancel')">稍后</a-button>
                    <a-button @click="clickUpdate" style="margin-left: 8px;" key="submit" type="primary">立即更新</a-button>
                </div>
            </div>
        </div>
    </a-modal>
    <a-modal
        v-if="isDownload" 
        :visible="visible" 
        :closable ="false"
        :centered="true" 
        :width="460"
        :maskClosable="false"
        title=""
        :body-style="{ padding: '0' }"
        :footer="null"
        style="-webkit-app-region: no-drag"
    >
        <div class="software-update" style="text-align: center;">
            <div style="font-size:17px;margin-bottom: 20px;">版本升级中，预计等待{{ remaining }}</div>
            <div style="margin-bottom: 20px;">
                <a-progress 
                    type="circle" 
                    :percent="percentage" 
                />
                <div style="margin-top: 20px;color: #959595;">
                    <span>下载速度{{ speed }}/s </span>  
                    <span style="margin-left: 15px;">{{ transferred }}/{{ total }}</span>
                </div>
            </div>
            <a-button type="primary" :disabled="disabled">{{percentage!=100 ? '下载中...' : '安装中...'}}</a-button>
        </div>
    </a-modal>
</template>
<script>
import { defineComponent,ref} from 'vue';

import common from '@/utils/common.js';

import config from '../../package.json';

export default defineComponent({
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        data:{
            type: Object,
            default: {}
        }
    },
    setup(props) {
      
        const {shell ,app}= require('@electron/remote')

        const path = require('path')
        const fs = require('fs-extra');
        const execSync = require("child_process").execSync;

        const request = require('request');
        const progress = require('request-progress');


        const oldversion = ref(config.version);

        const isDownload = ref(false);
        const disabled = ref(false);

        const remaining = ref(''); //估计时间
        const percentage = ref(0); //总进度
        const speed = ref('0MB'); //速度
        const total =ref('0MB');  //总大小
        const transferred = ref('0MB'); //有效大小

        const downloadUrl = props.data.downloadurl;
        const installerPath = path.join(app.getPath('temp'), process.platform === 'darwin' ? 'installer.dmg' : 'installer.exe');

        const clickUpdate = () => {
            progress(request(downloadUrl))
            .on('progress', (state) => {
                isDownload.value = true;
                disabled.value = true;
                remaining.value = common.formatSeconds(Math.round(state.time.remaining));
                percentage.value = Math.round(state.percent*100);
                speed.value = common.sizeTostr(Math.round(state.speed));
                total.value = common.sizeTostr(Math.round(state.size.total));
                transferred.value = common.sizeTostr(Math.round(state.size.transferred));
            })
            .on('end', function () {
                remaining.value = 0 + '秒';
                percentage.value = 100;
                speed.value = '0MB';
                transferred.value = total.value;

                shell.openPath(installerPath); // 打开下载好的安装程序
                app.quit();

                //  //mac
                //  if(process.platform === 'darwin'){
                //     //解压dmg文件内部资源，解压后的资源目录为/Volumes/your_installer
                //     execSync(`hdiutil attach ${installerPath} -nobrowse`, {
                //         stdio: ['ignore', 'ignore', 'ignore']
                //     });

                //     // 删掉原有的.app目录
                //     fs.removeSync('/Applications/猫嗅笔记.app'); 

                //     // 把Volumes目录下的.app目录拷贝到/Applications中，更新完毕
                //     fs.copySync('/Volumes/猫嗅笔记\ '+props.data.newversion+'/猫嗅笔记.app', '/Applications');

                //     // 重启应用
                //     app.relaunch();
                //     app.quit();
                // //wind
                // } else{
                //     shell.openPath(installerPath); // 打开下载好的安装程序
                //     app.quit();
                // }
            })
            .pipe(fs.createWriteStream(installerPath));
        }
        return{
            oldversion,

            isDownload,
            disabled,

            remaining,
            percentage,
            speed,
            total,
            transferred,

            clickUpdate
        }
    }
})
</script>
<style lang="less" scoped>
    .software-update{
        padding: 20px;
        box-sizing: border-box;
    }

    .software-update-title{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .software-update-msg{
        display: flex;
        align-items: center;
        margin-top: 25px;
    }

    .software-update-left{
        width: 42px;
        height: 42px;
    }

    .software-update-right{
        margin-left: 12px;
    }

    .software-update-content{
        height: 140px;
        border: 1px solid #eee;
        border-radius: 4px;
        padding: 8px 4px 8px 12px;
        margin-top: 13px;
        overflow-y: scroll;
        box-sizing: border-box;
    }
</style>