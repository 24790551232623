<template>
    <a-modal 
        :visible="visible" 
        :centered="true" 
        :maskClosable="false" 
        title="手机推品" 
        :footer="null"
        :body-style="{ padding: '15px 24px' }"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <div style="text-align: center;">
            <div style="background-color: #f5f5f5;padding: 30px 0 ;border-radius: 10px;">
                <img style="width: 200px;" src="../../assets/images/qrcode.jpg" />
                <div style="margin-top: 15px;margin-bottom: 15px;font-size: 14px;color: #666;">扫码关注公众号，手机推品更方便</div>
            </div>
        </div>
    </a-modal>
</template>
<script>
    import {defineComponent} from 'vue';
    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        setup(props,{ emit }){}
    })
</script>
<style lang="less" scoped>
   
</style>