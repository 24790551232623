<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="1000"
        :maskClosable="false"
        title="邀请奖励"
        :footer="null"
        :body-style="{ padding: '0px'}"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-card :bordered="false" :bodyStyle="{padding:'0px'}" style="border-radius: 10px;">
            <div class="account-settings-info-main">
                <div class="account-settings-info-left">
                    <a-menu mode="inline" v-model:selectedKeys="selectedKeys" style="border: 0;border-radius: 10px;">
                        <a-menu-item :key="1">
                            邀请企业
                        </a-menu-item>
                        <a-menu-item :key="2">
                            我的订单
                        </a-menu-item>
                        <a-menu-item :key="3">
                            我的收益
                        </a-menu-item>
                        <a-menu-item :key="4">
                            我的团队
                        </a-menu-item>
                        <a-menu-item :key="5">
                            奖励说明
                        </a-menu-item>
                    </a-menu>
                </div>
                <div class="account-settings-info-right">
                    <invite-organization v-if="selectedKeys[0] == 1"></invite-organization>
                    <distribution-order v-if="selectedKeys[0] == 2"></distribution-order>
                    <my-profit v-if="selectedKeys[0] == 3"></my-profit>
                    <my-teams v-if="selectedKeys[0] == 4"></my-teams>
                    <distribution-instructions v-if="selectedKeys[0] == 5"></distribution-instructions>
                </div>
            </div>
        </a-card>
    </a-modal>
</template>
<script>
    import {defineComponent,ref} from 'vue';

    import inviteOrganization from '@/components/distributionAccount/inviteOrganization';
    import distributionOrder from '@/components/distributionAccount/distributionOrder';
    import myProfit from '@/components/distributionAccount/myProfit';
    import myTeams from '@/components/distributionAccount/myTeams';
    import distributionInstructions from '@/components/distributionAccount/distributionInstructions';

    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        components: {
            inviteOrganization,
            distributionOrder,
            myProfit,
            myTeams,
            distributionInstructions
        },

        setup() {
            const selectedKeys = ref([1]);

            return {
                selectedKeys
            }
        }
    })
</script>
<style lang="less" scoped>
.account-settings-info-main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;
}

.account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width:120px;
}

.account-settings-info-right {
    flex: 1 1;
    max-height:550px;
    overflow:hidden;
    overflow-y:scroll;
    border-radius: 10px;
}
</style>