<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="1000"
        :maskClosable="false"
        title="股东分红"
        :footer="null"
        :body-style="{ padding: '0px'}"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
    <a-card :bordered="false" :bodyStyle="{padding:'0px'}" style="border-radius: 10px;">
            <div class="account-settings-info-main">
                <div class="account-settings-info-left">
                    <a-menu mode="inline" v-model:selectedKeys="selectedKeys" style="border: 0;border-radius: 10px;">
                        <a-menu-item :key="1">
                            团队订单
                        </a-menu-item>
                        <a-menu-item :key="2">
                            股东收益
                        </a-menu-item>
                        <a-menu-item :key="3">
                            我的团队
                        </a-menu-item>
                        <a-menu-item :key="4">
                            我的激活码
                        </a-menu-item>
                    </a-menu>
                </div>
                <div class="account-settings-info-right">
                    <teams-order v-if="selectedKeys[0] == 1"></teams-order>
                    <shareholder-profit v-if="selectedKeys[0] == 2"></shareholder-profit>
                    <my-teams v-if="selectedKeys[0] == 3"></my-teams>
                    <activation-code v-if="selectedKeys[0] == 4"></activation-code>
                </div>
            </div>
        </a-card> 
    </a-modal>
</template>
<script>
    import {defineComponent,ref} from 'vue';

    import teamsOrder from '@/components/shareholderAccount/teamsOrder';
    import shareholderProfit from '@/components/shareholderAccount/shareholderProfit';
    import myTeams from '@/components/shareholderAccount/myTeams';
    import activationCode from '@/components/shareholderAccount/activationCode';

    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        components: {
            teamsOrder,
            shareholderProfit,
            myTeams,
            activationCode,
        },

        setup() {
            const selectedKeys = ref([1]);

            return {
                selectedKeys
            }
        }
    })
</script>
<style lang="less" scoped>
.account-settings-info-main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;
}

.account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width:120px;
}

.account-settings-info-right {
    flex: 1 1;
    max-height:550px;
    overflow:hidden;
    overflow-y:scroll;
    border-radius: 10px;
}
</style>