import request from '@/utils/request'

const siteConfigApi = {
    EditSiteConfig:'/userAdminApi/site_config/editSiteConfig',
}

//编辑站点信息
export function editSiteConfig(parameter){
    return request({
        url: siteConfigApi.EditSiteConfig,
        method: 'post',
        data: parameter
    })
}