<template>
    <a-modal
        :visible="visible" 
        :centered="true" 
        :width="1000"
        :maskClosable="false"
        title="账户管理"
        :footer="null"
        :body-style="{ padding: '0px' }"
        @cancel="$emit('cancel')" 
        style="-webkit-app-region: no-drag"
    >
        <a-card :bordered="false" :bodyStyle="{padding:'0px'}" style="border-radius: 10px;">
            <div class="account-settings-info-main">
                <div class="account-settings-info-left">
                    <a-menu mode="inline" v-model:selectedKeys="selectedKeys" style="border: 0;border-radius: 10px;">
                        <a-menu-item :key="1">
                            服务订单
                        </a-menu-item>
                        <!-- <a-menu-item :key="2">
                            发票管理
                        </a-menu-item> -->
                    </a-menu>
                </div>
                <div class="account-settings-info-right">
                    <order-list v-if="selectedKeys[0] == 1"></order-list>
                    <!-- <invoice-list v-if="selectedKeys[0] == 2"></invoice-list> -->
                </div>
            </div>
        </a-card>
    </a-modal>
</template>
<script>
    import {defineComponent,ref} from 'vue';

    import orderList from '@/components/account/orderList';
    import invoiceList from '@/components/account/invoiceList';

    export default defineComponent({
        props: {
			visible: {
				type: Boolean,
				default: false
			},
		},

        components: {
            orderList,
            invoiceList
        },

        setup() {
            const selectedKeys = ref([1]);

            return {
                selectedKeys
            }
        }
    })
</script>
<style lang="less" scoped>
.account-settings-info-main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;
}

.account-settings-info-left {
    border-right: 1px solid #e8e8e8;
    width:120px;
}

.account-settings-info-right {
    flex: 1 1;
}
</style>