import { defineStore } from 'pinia';
import storage from 'store'
import {changeMobile,changePassword,organizationLogin,mobileLoginCheck,mobileSmsLoginCheck,forgotPasswordLoginCheck, register,join,getUserAndOrganizationInfo, updateUserInfo,logout} from '@/api/user'
import { send ,check} from '@/api/sms'
import { editSiteConfig} from '@/api/siteConfig'
import { getApplyBadgeSubCount} from '@/api/friendApply'
import useAsyncRouter from '@/stores/async-router';

const useUser = defineStore({
    id:'user',
    state(){  // 存放的就是模块的变量
        return{
            isCollapse:storage.get('isCollapse') ? storage.get('isCollapse') : false,
            badgeSubCount:0,
            userInfo:{},
        }
    },

    getters:{ // 相当于vue里面的计算属性，可以缓存数据
        
    },

    actions:{ // 可以通过actions 方法，改变 state 里面的值。

        SetCollapse(value){
            this.isCollapse = value;
            storage.set('isCollapse', this.isCollapse);
        },

        GetApplyBadgeSubCount(){
            getApplyBadgeSubCount().then(res => {
                this.badgeSubCount = res.result;
            })
        },

        //修改手机
        ChangeMobile(values){
            return new Promise((resolve, reject) => {
                changeMobile(values).then(response => {
                    this.GetUserAndOrganizationInfo();
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })  
        },

        //修改密码
        ChangePassword(values){
            return new Promise((resolve, reject) => {
                changePassword(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })   
        },
        
        //发送验证码
        SmsSend(values){
            return new Promise((resolve, reject) => {
                send(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //验证码检测
        SmsCheck(values){
            return new Promise((resolve, reject) => {
                check(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //选择企业登录
        OrganizationLogin(values){
            return new Promise((resolve, reject) => {
                organizationLogin(values).then(response => {
                    const result = response.result;
                    if(response.status == 1){
                        storage.set('token', result);
                    }
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //手机号密码检测
        MobileLoginCheck(values){
            return new Promise((resolve, reject) => {
                mobileLoginCheck(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //手机号验证码检测
        MobileSmsLoginCheck(values){
            return new Promise((resolve, reject) => {
                mobileSmsLoginCheck(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //重置密码
        ForgotPasswordLoginCheck(values){
            return new Promise((resolve, reject) => {
                forgotPasswordLoginCheck(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //注册
        Register(values){
            return new Promise((resolve, reject) => {
                register(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //加入
        Join(values){
            return new Promise((resolve, reject) => {
                join(values).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },
        
        //获取用户及企业信息
        GetUserAndOrganizationInfo(){
            return new Promise((resolve, reject) => {
                getUserAndOrganizationInfo().then(response => {
                    const result = response.result;
                    this.userInfo = result;
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //更新用户信息
        UpdateUserInfo(values){
            return new Promise((resolve, reject) => {
                updateUserInfo(values).then(response => {
                    this.userInfo['avatar'] = values['avatar'];
                    this.userInfo['name'] = values['name'];
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //编辑站点信息
        EditSiteConfig(values){
            return new Promise((resolve, reject) => {
                editSiteConfig(values).then(response => {
                    this.userInfo['price_display'] = values['price_display']
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //退出登录
        Logout(){
            return new Promise((resolve) => {
                logout().then((res) => {
                    storage.remove('token');
                    
                    const asyncRouterStore = useAsyncRouter();
                    asyncRouterStore.addRouters = [];

                    resolve();
                })
            })
        }
    }
})

export default useUser;