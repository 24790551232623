import request from '@/utils/request'

const smsApi = {
    Send:'/common/sms/send',
    Check:'/common/sms/check',
}

//发送验证码
export function send(parameter){
    return request({
        url: smsApi.Send,
        method: 'post',
        data: parameter
    })
}

//验证码检测
export function check(parameter){
    return request({
        url: smsApi.Check,
        method: 'post',
        data: parameter
    })
}