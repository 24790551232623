<template>
    <div>
        <a-card :bordered="false" :bodyStyle="{padding:'16px'}" >
            <a-typography-title :level="5" style="margin-bottom: 20px;">我的收益</a-typography-title>
            <div style="margin-top: 16px;margin-bottom: 16px;">
                <a-table 
                    ref="table"
                    size="middle" 
                    :row-key = "record => record.id"
                    :columns = "[
                        {
                            title: '累计企业',
                            dataIndex: 'total_organization_count',
                            align: 'left',
                        },
                        {
                            title: '累计收益',
                            dataIndex: 'distribution_total_balance',
                            align: 'left',
                        },
                        {
                            title: '直邀会员数',
                            dataIndex: 'directUserListCount',
                            align: 'left',
                        },
                        {
                            title: '直接收益',
                            dataIndex: 'direct_total_balance',
                            align: 'left',
                        },
                        {
                            title: '间邀会员数',
                            dataIndex: 'indirectUserListCount',
                            align: 'left',
                        },
                        {
                            title: '间接收益',
                            dataIndex: 'indirect_total_balance',
                            align: 'left',
                        },
                        {
                            title: '已提现',
                            dataIndex: 'distribution_balance_expenditure',
                            align: 'left',
                        },
                        {
                            title: '还剩余',
                            dataIndex: 'distribution_balance_surplus',
                            align: 'left',
                        }
                    ]"
                    :data-source = "[
                        {
                            'total_organization_count' : balanceInfo && balanceInfo['totalOrganizationCount'] ? balanceInfo['totalOrganizationCount'] : 0,
                            'distribution_total_balance':balanceInfo && balanceInfo['distribution_total_balance'] ? balanceInfo['distribution_total_balance'] : '0.00',
                            'directUserListCount' : balanceInfo && balanceInfo['directUserListCount'] ? balanceInfo['directUserListCount'] : 0,
                            'direct_total_balance' : balanceInfo && balanceInfo['directTotalBalance'] ? balanceInfo['directTotalBalance'] : '0.00',
                            'indirectUserListCount' : balanceInfo && balanceInfo['indirectUserListCount'] ? balanceInfo['indirectUserListCount'] : 0,
                            'indirect_total_balance' :  balanceInfo && balanceInfo['indirectTotalBalance'] ? balanceInfo['indirectTotalBalance'] :'0.00',
                            'distribution_balance_expenditure':balanceInfo && balanceInfo['distribution_balance_expenditure'] ? balanceInfo['distribution_balance_expenditure'] : '0.00',
                            'distribution_balance_surplus':balanceInfo && balanceInfo['distribution_balance_surplus'] ? balanceInfo['distribution_balance_surplus'] : '0.00'
                        }
                    ]" 
                    :pagination="false"
                    :scroll = "{ x: 'max-content'}"
                >
                </a-table>
            </div>
            <a-typography-title :level="5" style="margin-bottom: 20px;">余额明细</a-typography-title>
            <div style="margin-top: 16px;">
                <a-table 
                    ref="table"
                    size="middle" 
                    :row-key = "record => record.id"
                    :columns = "columns"
                    :data-source = "dataSource" 
                    :pagination = "pagination"
                    :loading = "loading"
                    @change = "handleTableChange"
                    :scroll = "{ x: 'max-content'}"
                >
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'type'">
                            <span v-if="text == 1">收益</span>
                            <span v-if="text == 2">分账</span>
                        </template>
                        <template v-if="column.dataIndex === 'money'">
                            <span v-if="record['action'] == 1">+ {{text}}</span>
                            <span v-if="record['action'] == 2">- {{text}}</span>
                        </template>
                    </template>
                </a-table>
            </div>
        </a-card>
    </div>
</template>
<script>
    import { defineComponent,ref,computed,onMounted} from 'vue';
    import { usePagination } from 'vue-request';
    import {getDistributionUserBalance,getDistributionUserBalanceLog} from '@/api/distribution'
    export default defineComponent({
        setup() {
            const balanceInfo = ref();
            onMounted(() => {
                getDistributionUserBalance().then((res) => {
                    balanceInfo.value = res.result
                })
            })

            //table
            const columns = [
                {
                    title: '入账时间',
                    dataIndex: 'createtime',
                    align: 'left',
                },
                {
                    title: '财务类型',
                    dataIndex: 'type',
                    align: 'left',
                },
                {
                    title: '金额',
                    dataIndex: 'money',
                    align: 'left',
                },
                {
                    title: '备注',
                    dataIndex: 'memo',
                    align: 'left',
                },
            ];

            const queryData = params => {
                return getDistributionUserBalanceLog(params).then((res) => {
                    return res.result;
                })
            };

            const {
                data,
                run,
                loading,
                current,
                pageSize,
                refresh
            } = usePagination(queryData);

            const dataSource = computed(() => data.value ? data.value.data : []);

            const pagination = computed(() => (
                {
                    total: data.value ? data.value.totalCount : '',
                    current: current.value,
                    pageSize: pageSize.value,
                    showTotal:(total) => `共 ${total} 条`,
                    pageSizeOptions:['10', '20', '30', '40', '50', '100'],
                    showSizeChanger:true,
                }
            ));

            const handleTableChange = (pag, filters, sorter) => {
                run({
                    current:pag.current,
                    pageSize:pag.pageSize,
                })
            }

            return {
                balanceInfo,
                
                queryData,
                columns,
                dataSource,

                pagination,
                loading,
                handleTableChange,
            }
        }
    })
</script>